import { MdDelete } from 'react-icons/md';
import { FaSave, FaPen } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import styled from 'styled-components';

interface IProps {
  editing: boolean;
  onDelete: () => void;
  onCancel: () => void;
  onEdit: () => void;
}

export const Actions = ({ editing, onDelete, onCancel, onEdit }: IProps) => {
  return (
    <Container>
      {editing ? (
        <>
          <Save type='submit'>
            <SaveIcon />
          </Save>

          <Delete type='button' onClick={onDelete}>
            <DeleteIcon />
          </Delete>

          <Cancel type='button' onClick={onCancel}>
            <CancelIcon />
          </Cancel>
        </>
      ) : (
        <Edit onClick={onEdit}>
          <EditIcon />
        </Edit>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 12px;
`;

const Save = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--chakra-colors-green-primary);
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const Delete = styled(Save)`
  background: var(--chakra-colors-red-negative);

  &:hover {
    background: var(--chakra-colors-red-negativeHover);
  }
`;

const Cancel = styled(Save)`
  background: var(--chakra-colors-yellow-primary);

  &:hover {
    background: var(--chakra-colors-yellow-primaryHover);
  }
`;

const Edit = styled(Save)`
  background: var(--chakra-colors-yellow-primary);

  &:hover {
    background: var(--chakra-colors-yellow-primaryHover);
  }
`;

const EditIcon = styled(FaPen)`
  color: var(--chakra-colors-black-primary);
  font-size: 14px;
`;

const SaveIcon = styled(FaSave)`
  color: var(--chakra-colors-green-degraded);
  font-size: 16px;
`;

const CancelIcon = styled(IoCloseSharp)`
  color: var(--chakra-colors-black-primary);
  font-size: 20px;
`;

const DeleteIcon = styled(MdDelete)`
  color: var(--chakra-colors-red-degraded);
  font-size: 20px;
`;
