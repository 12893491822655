import styled from 'styled-components';

interface IProps {
  videoUrl: string;
  poster: string;
}

export const Video = ({ videoUrl, poster }: IProps) => {
  return (
    <VideoComponent
      controls={true}
      controlsList='nodownload'
      disablePictureInPicture={true}
      poster={poster}
    >
      {videoUrl.length && <source src={videoUrl} />}
    </VideoComponent>
  );
};

const VideoComponent = styled.video`
  width: 100%;
  height: auto;
  min-height: 436px;
  border-radius: 12px;
`;
