import styled, { css } from 'styled-components';
import { SelectComponent } from './select';

type option = { id: number; name: string };

interface IProps {
  config: {
    register: any;
    errors: any;
    disabled?: boolean;
    getValues: (name: string) => option;
    setValue: any;
    clear?: boolean;
    setError: any;
  };
  name: string;
  label: string;
  options: {
    id: number | string;
    name: string;
  }[];
  isRequired?: boolean;
}

export const CustomSelect = ({ name, config, options, label, isRequired }: IProps) => {
  return (
    <FormGroup>
      <Label editing={!config.disabled}>{label}</Label>
      <input type='hidden' {...config.register(name)} />
      <SelectComponent
        options={options}
        sendSelectedOption={value => {
          config.setError(name);
          config.setValue(name, value);
        }}
        optionSelectedDefault={config.getValues(name)}
        disabled={config.disabled}
        clear={config.clear || false}
      />
      {config.errors[name] && <ErrorMessage>{config.errors[name].message}</ErrorMessage>}
    </FormGroup>
  );
};

const FormGroup = styled.label`
  display: block;
  width: 100%;
  margin: 4px 0;
  position: relative;
  border-radius: 8px;
  border: 2px solid transparent;
`;

type ILabel = {
  editing?: boolean;
  isRequired?: boolean;
};

const Label = styled.p`
  display: inline-block;
  margin: auto;
  width: auto;
  font-size: 14px;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);

  ${({ editing }: ILabel) =>
    editing &&
    css`
      color: #109cf1;

      ${({ isRequired }: ILabel) =>
        !isRequired &&
        css`
          &:after {
            content: ' *';
            color: rgba(230, 55, 70, 1);
          }
        `}
    `}
`;

const ErrorMessage = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(230, 55, 70, 1);
  border-radius: 4px;
  margin-top: 4px;
`;
