import { useMediaQuery } from '@chakra-ui/media-query';
import { CircularProgress } from '@chakra-ui/progress';
import { useEffect, useRef, useState } from 'react';
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import { ScreenshotButton, SwitchButton } from './Buttons/Buttons';

interface IVideo {
  setPicture: (value: string) => void;
  picture?: string;
}

export const Video = ({ setPicture, picture }: IVideo) => {
  const [loading, setLoading] = useState(true);
  const [movileCamera, setMovileCamera] = useState<boolean>(true);
  const [showImageViewer, setShowImageViewer] = useState(false);

  const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)');

  const webcamRef = useRef<Webcam>(null);

  useEffect(() => {
    setMovileCamera(isLargerThan1440 ? false : true);
  }, [isLargerThan1440]);

  const capture = () => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    setPicture(String(imageSrc));
  };

  return (
    <VideoContainer>
      <Webcam
        ref={webcamRef}
        videoConstraints={{ facingMode: { exact: movileCamera ? 'environment' : 'user' } }}
        onLoadedData={() => setLoading(false)}
        width='100%'
        height='700px'
        mirrored={true}
      />
      {picture && <Picture src={picture} alt='stores' onClick={() => setShowImageViewer(true)} />}
      {picture && showImageViewer && (
        <ReactSimpleImageViewer
          src={[picture]}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setShowImageViewer(false)}
          backgroundStyle={{ background: 'rgba(34,34,34, .7)' }}
        />
      )}
      {!loading && (
        <>
          <SwitchButton onClick={() => setMovileCamera(!movileCamera)} />
          <ScreenshotButton onClick={capture} />
        </>
      )}
      {loading && <Loader />}
    </VideoContainer>
  );
};

const Picture = styled.img`
  position: absolute;
  width: 60px;
  height: 60px;
  bottom: 1rem;
  left: 1rem;
  border-radius: 10px;
  border: 3px solid rgba(256, 256, 256, 0.5);
  object-fit: cover;
  cursor: pointer;
`;

const Loader = () => (
  <CircularProgress
    position='absolute'
    top='calc(50% - 30px)'
    left='calc(50% - 30px)'
    isIndeterminate={true}
    color='var(--chakra-colors-yellow-primary)'
  />
);

const VideoContainer = styled.div`
  position: relative;
  height: fit-content;
`;
