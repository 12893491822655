import { FC, Fragment, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const Logout: FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: window.location.origin + '/#/logout' });
  }, [logout]);

  return <Fragment />;
};
