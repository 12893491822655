import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPosition } from 'components/common/Map/Map';
import {
  CLienInfo,
  ClientDetails,
  Contactos,
  IPostSucursales,
  Sucursales,
} from '../../../models/ClientDetails';
import { RootState } from '../../../store/store';
import { createContactReducer } from './extraReducers/CreateContact';
import { createStoreReducer } from './extraReducers/CreateStore';
import { deleteImageReducer } from './extraReducers/DeleteImage';
import { getClientReducer } from './extraReducers/getCLientInfo';
import { getGeolocationReducer } from './extraReducers/getGeolocation';
import { saveImageReducer } from './extraReducers/SaveImageInDb';
import { updateContactReducer } from './extraReducers/UpdateClientContact';
import { updateClientInfoReducer } from './extraReducers/UpdateCLientInfo';
import { updateStoreReducer } from './extraReducers/UpdateStore';

export enum EMainNav {
  informacion,
  contacto,
  tiendas,
}

export interface IPostStore extends Sucursales {
  idCliente: string;
  id: number;
}

export interface ClienteProfileState {
  status: StatusApp.Status;
  message: string;
  clientDetails: ClientDetails;
  modifiedClientInfo: CLienInfo;
  modifyContact: Contactos;
  stores: {
    currentStore: Sucursales;
    modifiedStore: IPostSucursales;
    action: 'updating' | 'creating';
    status: StatusApp.Status;
    existSucursal: boolean;
    map: {
      modifiedLocalization: IPosition;
      viewMap: IPosition;
    };
  };
  mainNav: EMainNav;
}

const initialState: ClienteProfileState = {
  status: 'succeeded',
  message: '',
  clientDetails: {
    cliente: {} as CLienInfo,
    contactos: [],
    sucursales: [],
  },
  modifiedClientInfo: {} as CLienInfo,
  modifyContact: {} as Contactos,
  stores: {
    currentStore: {
      images: [],
    } as unknown as Sucursales,
    modifiedStore: {} as IPostSucursales,
    action: 'updating',
    status: 'idle',
    existSucursal: false,
    map: {
      modifiedLocalization: {} as IPosition,
      viewMap: {} as IPosition,
    },
  },
  mainNav: EMainNav.informacion,
};

export const clientProfileSlice = createSlice({
  name: 'clientProfile',
  initialState,
  reducers: {
    onSaveChanges: (state, action: PayloadAction<CLienInfo>) => {
      state.modifiedClientInfo = action.payload;
    },
    setMessageEmpty: state => {
      state.message = '';
    },
    setModifiedContact: (state, action: PayloadAction<Contactos>) => {
      state.modifyContact = action.payload;
    },
    setCurrentStore: (state, action: PayloadAction<{ id: number | undefined }>) => {
      if (isNaN(Number(action.payload.id))) {
        state.stores.currentStore = {} as Sucursales;
        return;
      }
      const store = state.clientDetails.sucursales.find(
        stores => stores.codigo === action.payload.id
      ) as Sucursales;
      state.stores.currentStore = store;
    },
    setStoreStatus: (state, action: PayloadAction<ClienteProfileState['stores']['action']>) => {
      state.stores.action = action.payload;
    },
    setMainNav: (state, action: PayloadAction<EMainNav>) => {
      state.mainNav = action.payload;
    },
    setModifiedStore: (state, action: PayloadAction<IPostStore>) => {
      state.stores.modifiedStore = action.payload;
    },
    setModifiedPosition: (state, action: PayloadAction<IPosition>) => {
      state.stores.map.modifiedLocalization = action.payload;
    },
    setViewMap: (state, action: PayloadAction<IPosition>) => {
      state.stores.map.viewMap = action.payload;
    },
  },
  extraReducers: builder => {
    // client info section
    getClientReducer(builder);
    updateClientInfoReducer(builder);
    // contacts section
    createContactReducer(builder);
    updateContactReducer(builder);
    // sucursal and stores section
    createStoreReducer(builder);
    updateStoreReducer(builder);
    // convert base64 to file and upload to firebase and db
    saveImageReducer(builder);
    // delete image from db and firebase
    deleteImageReducer(builder);
    // para obtener las coordenadas cuando buscamos un lugar por nombr een el mapa
    getGeolocationReducer(builder);
  },
});

export const {
  onSaveChanges,
  setMessageEmpty,
  setModifiedContact,
  setCurrentStore,
  setStoreStatus,
  setMainNav,
  setModifiedStore,
  setModifiedPosition,
  setViewMap,
} = clientProfileSlice.actions;

export const selectClientProfile = (state: RootState) => state.clientDetails;

export const clienteProfileReducer = clientProfileSlice.reducer;
