import { ColDef } from 'ag-grid-community/main';

export const columns: ColDef[] = [
  {
    flex: 1,
    field: 'codigoTams',
    headerName: 'Código',
    sortable: true,
    maxWidth: 80,
  },
  {
    flex: 1,
    field: 'nombre',
    headerName: 'Nombre',
    wrapText: true,
    resizable: true,
    autoHeight: true,
    minWidth: 180,
  },
  {
    flex: 1,
    field: 'telefono1',
    headerName: 'Teléfono',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'direccion',
    headerName: 'Dirección',
    wrapText: true,
    resizable: true,
    minWidth: 250,
    autoHeight: true,
  },
  {
    flex: 1,
    field: 'estado',
    headerName: 'Estado',
    autoHeight: true,
    maxWidth: 100,
    minWidth: 100,
    resizable: false,
    cellRenderer: 'activo',
    sort: 'asc',
    sortable: true,
    initialSort: 'asc',
  },
];
