import { IStoresForm } from 'components/ClientProfile/StoresInfo/StoresForm/StoresForm';
import { object, string, SchemaOf } from 'yup';

const required = 'Campo requerido';
const min = 'No pueden ser menos de 8 numeros';

export const storesClientSchema: SchemaOf<IStoresForm> = object()
  .shape({
    nombre: string().required(required).defined(),
    direccion: string().required(required).defined(),
    telefono: string().required(required).min(8, min).defined(),
    contacto: string().required(required).defined(),
  })
  .defined();
