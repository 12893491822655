/* eslint-disable @typescript-eslint/no-unused-vars */
import { BsFillPeopleFill } from 'react-icons/bs';
import { MdFolderOpen, MdStoreMallDirectory } from 'react-icons/md';
import styled, { css } from 'styled-components';

type objectBoolean = {
  catalog: boolean;
  sellers: boolean;
  clientes: boolean;
};

interface IProps {
  options: objectBoolean;
  optionsDefault: objectBoolean;
  selectOption: (value: objectBoolean) => void;
}

const NavBar = ({ options, optionsDefault, selectOption }: IProps) => {
  return (
    <AllOptions>
      <Option
        active={options.catalog}
        onClick={() => selectOption({ ...optionsDefault, catalog: true })}
      >
        <CatalogIcon />
        <Text>Catalogo</Text>
      </Option>

      <Option
        active={options.sellers}
        onClick={() => selectOption({ ...optionsDefault, sellers: true })}
      >
        <SellersIcon />
        <Text>Vendedores</Text>
      </Option>
    </AllOptions>
  );
};

export default NavBar;

const AllOptions = styled.div`
  width: calc(100% - 24px);
  max-width: 1200px;
  margin: 12px auto 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(121px, 121px));
  column-gap: 12px;
`;

const Option = styled.div`
  width: 121px;
  height: 75px;
  border-radius: 12px;
  padding: 4px;
  border: 2px solid var(--chakra-colors-yellow-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: var(--chakra-colors-white-primary);
  transition: 0.3s ease-in-out;

  &:hover {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(244, 236, 34, 0.1);
    }
  }

  ${({ active }: { active: boolean }) =>
    active &&
    css`
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(244, 236, 34, 0.1);
      }
    `}
`;

const SellersIcon = styled(BsFillPeopleFill)`
  font-size: 40px;
  color: var(--chakra-colors-black-primary);
`;

const CatalogIcon = styled(MdFolderOpen)`
  font-size: 40px;
  color: var(--chakra-colors-black-primary);
`;

const ClientesIcon = styled(MdStoreMallDirectory)`
  font-size: 40px;
  color: var(--chakra-colors-black-primary);
`;

const Text = styled.p`
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  margin-top: 4px;
  font-weight: 700;
`;
