import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendProductUpdated = createAsyncThunk('catalog/updateProduct', httpService);

export const updateProductReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendProductUpdated.fulfilled, state => {
    state.updateProduct.status = 'succeeded';
    state.updateProduct.message = 'Producto actualizado';
  });

  builder.addCase(sendProductUpdated.pending, state => {
    state.updateProduct.status = 'pending';
  });

  builder.addCase(sendProductUpdated.rejected, state => {
    state.updateProduct.status = 'failed';
    state.updateProduct.message = 'Error al actualizar producto';
  });
};
