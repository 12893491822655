import { Container } from 'components/common/Container/Container';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  IPostStore,
  setCurrentStore,
  setModifiedStore,
} from '../../../features/ClientProfile/store/store';
import { StoresForm } from './StoresForm/StoresForm';
import { StoresGalery } from './StoresGalery/StoresGalery';
import { StoresHeader } from './StoresHeader/StoresHeader';
import { StoresNav } from './StoresNav/StoresNav';

export enum EStoresNav {
  informacion,
  geleria,
}

export const StoresInfo = () => {
  const [section, setSection] = useState(EStoresNav.informacion);
  const sections = {
    [EStoresNav.informacion]: <StoresForm />,
    [EStoresNav.geleria]: <StoresGalery />,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setCurrentStore({ id: NaN }));
      dispatch(setModifiedStore({} as IPostStore));
    };
  }, [dispatch]);

  return (
    <Container>
      <StoresHeader />
      <StoresNav setSection={setSection} section={section} />
      {sections[section]}
    </Container>
  );
};
