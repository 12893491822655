import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from 'components/common/CustomInput/CustomInput';
import { FormSection } from 'components/common/FormSection/FormSection';
import { Modal } from 'components/common/Modal/Modal';
import { Contactos } from 'models/ClientDetails';
import { SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClientContactSchema } from 'schemes/contactClient';

export interface IModal {
  value: boolean;
  type: 'PUT' | 'POST';
}

interface IModalForm {
  setShowModal: (value: SetStateAction<IModal>) => void;
  showModal: IModal;
  currentContact: Contactos;
  onSuccesSave: (data: formValues) => void;
}

export type formValues = {
  id: number;
  nombre: string;
  puesto: string;
  correo: string;
  telefono: string;
  celular: string;
};

export const ModalForm = ({
  setShowModal,
  showModal,
  currentContact,
  onSuccesSave,
}: IModalForm) => {
  const [formEditing, setFormEditing] = useState<boolean>(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<formValues>({
    resolver: yupResolver(ClientContactSchema),
  });

  const onSubmit: SubmitHandler<formValues> = data => {
    setShowModal({ ...showModal, value: showModal.type === 'POST' ? false : true });
    setFormEditing(false);
    onSuccesSave(data);
  };

  useEffect(() => {
    setFormEditing(true);
  }, [showModal.type]);

  useEffect(() => {
    reset(currentContact);
  }, [currentContact, reset]);

  const onSave = () => handleSubmit(onSubmit);

  const onEditCancel = () => {
    setShowModal({ ...showModal, value: showModal.type === 'POST' ? false : true });
    showModal.type === 'PUT' && reset(currentContact);
    setShowModal(value => ({ ...value, value: false }));
  };

  const commonsProps = {
    errors,
    register,
    editing: formEditing,
  };

  return (
    <Modal
      openModal={showModal.value}
      closeModal={() => setShowModal(value => ({ ...value, value: false }))}
      noCloseButton={true}
    >
      <FormSection
        title={showModal.type === 'POST' ? 'Crear contacto' : 'Editar contacto'}
        onSave={onSave()}
        titlePosition='center'
        editing={formEditing}
        onEditCancel={onEditCancel}
        setEditing={setFormEditing}
        grid={{
          templateColumns: 'repeat(auto-fit,minmax(300px,370px))',
          templateRows: 'repeat(5,auto)',
          justifyContent: 'center',
          gridGap: '12px',
        }}
      >
        <CustomInput
          {...commonsProps}
          placeholder='Erick Marley Arita'
          name='nombre'
          label='Contacto'
          isRequired={true}
        />
        <CustomInput
          {...commonsProps}
          placeholder='Gerente'
          name='puesto'
          label='Puesto'
          isRequired={true}
        />
        <CustomInput
          {...commonsProps}
          placeholder='email@email.com'
          name='correo'
          label='Correo'
          isRequired={true}
        />
        <CustomInput
          {...commonsProps}
          placeholder='98981212'
          name='telefono'
          label='Teléfono'
          isRequired={true}
        />
        <CustomInput
          {...commonsProps}
          placeholder='98981212'
          name='celular'
          label='Celular'
          isRequired={true}
        />
      </FormSection>
    </Modal>
  );
};
