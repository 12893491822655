import { Camera } from 'components/common/Camera/Camera';
import { Modal, ModalOverlay, ModalContent, useMediaQuery } from '@chakra-ui/react';

interface ICameraModal {
  isOpen: boolean;
  onClose: () => void;
  onSave: (base64: string) => void;
  children?: JSX.Element;
}

export const CameraModal = ({ isOpen, onClose, onSave }: ICameraModal) => {
  const [isLargerThan768] = useMediaQuery('(max-width: 778px)');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      closeOnEsc={true}
      size={isLargerThan768 ? 'full' : '3xl'}
    >
      <ModalOverlay>
        <ModalContent h='100vh'>
          <Camera onSave={onSave} onClose={onClose} />
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
