import { getDownloadURL, ref, storage, uploadBytes } from '../firebase/storageConfig';

export enum ERutes {
  stores = 'Stores/',
  products = 'Products/',
  brands = 'Brands/',
}

interface IUploadImage {
  file: any;
  path: ERutes;
  name: string;
}

export const uploadImage = async ({ file, name, path }: IUploadImage) => {
  const storageRef = ref(storage, `${path}${name}${Date.now()}.webp`);
  const snapshot = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(snapshot.ref);
  return url;
};
