import { extendTheme } from '@chakra-ui/react';

export const styles = {
  fonts: {
    heading: 'Nunito, sans-serif',
    body: 'Nunito, sans-serif',
  },
  colors: {
    yellow: {
      primary: '#F4EC22',
      primaryHover: '#ECE415',
    },
    red: {
      primary: '#F7685B',
      degraded: '#FDF4F7',
      negative: '#FF3C5F',
      negativeHover: '#e23454',
    },
    green: {
      primary: '#2ED47A',
      degraded: '#EBF9F5',
      primaryHover: '#27b668',
    },
    black: {
      primary: '#271F20',
      table: '#323C47',
    },
    gray: {
      primary: '#90A0B7',
      table: '#707683',
      icon: '#C2CFE0',
      iconHover: '#AFBCCD',
    },
    blue: {
      text: '#334D6E',
    },
    white: {
      primary: '#FFFFFF',
    },
    background: {
      gray: '#f5f6f8',
    },
  },
  styles: {
    global: {
      body: {
        bg: '#F5F6F8',
      },
    },
  },
};

export const theme = extendTheme(styles);
