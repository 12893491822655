import { Modal as ModalComponent, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import styled, { css } from 'styled-components';

interface IProps {
  /**
   * @param children
   * @type JSX.Element
   * @description Es el contenido del modal
   */
  children: JSX.Element;
  /**
   * @param openModal
   * @type boolean
   * @description Es un estado en el que se define si la modal esta abierta o cerrada
   */
  openModal: boolean;
  /**
   * @param closeModal
   * @type boolean
   * @description  Se le pasa una función para cerrar la modal
   * @example  <Modal closeModal={() => setOpenModal(false)}
   */
  closeModal: () => void;
  /**
   * @param noCloseButton
   * @type boolean
   * @description  Oculta el botón de cerrar modal
   */
  noCloseButton?: boolean;
  size?: {
    width: number;
    height: number;
  };
}

/**
 * @function Modal
 * @description Este componente no se encarga de nada mas que mostrar la información del children
 * el contenedor trae un position absolute si se necesita colocar algo en una parte especifica se puede crear luego
 * pasar como children y darle la propiedad de position absolute para colocar tu elemento en cualquier parte
 * del modal
 */
export const Modal = ({ children, openModal, closeModal, noCloseButton = false, size }: IProps) => {
  return (
    <ModalComponent isOpen={openModal} onClose={closeModal} isCentered={true} closeOnEsc={true}>
      <ModalOverlay>
        <ModalContent>
          <Container size={size}>
            {!noCloseButton && <Close onClick={closeModal} />}
            {children}
          </Container>
        </ModalContent>
      </ModalOverlay>
    </ModalComponent>
  );
};

const Close = styled(CgClose)`
  font-size: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: pointer;
`;

const Container = styled.div`
  width: 502px;
  min-height: 580px;
  border-radius: 12px;
  background: var(--chakra-colors-white-primary);
  border: 1px solid #f0f0f0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;

  ${({ size }: { size: { width: number; height: number } | undefined }) =>
    size !== undefined &&
    css`
      width: ${size.width}px;
      height: ${size.height}px;
      min-height: ${size.height}px;
    `}
`;
