import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const Lines = () => {
  const { lines } = useAppSelector(state => state.products);

  return (
    <Container>
      {lines.slice(0, 8).map(line => (
        <Line key={line.id}>{line.name}</Line>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Line = styled.p`
  width: auto;
  padding: 4px;
  border: 2px solid var(--chakra-colors-yellow-primary) !important;
  border-radius: 8px;
  margin: 0 auto 8px 0;
  text-align: start;
  font-size: 1em;
  font-weight: 600;
  position: relative;
  cursor: default;
`;
