import styled from 'styled-components';
import { FC } from 'react';

export const Inicio: FC = () => {
  return (
    <Container>
      <Image src='https://firebasestorage.googleapis.com/v0/b/chicsa-dev/o/welcomeLogo.svg?alt=media&token=cea882af-05ef-4928-8289-ab7be047ea51' />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  background: #fff;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/chicsa-dev/o/welcomeBackground.svg?alt=media&token=52a5f5b9-4405-4328-bb7d-6d93fd632703');
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: auto;
  max-width: 350px;
  height: auto;
`;
