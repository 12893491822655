import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct } from '../../../models/Products';
import { initialState } from './common';
import { getProductsReducer } from './extraReducers/getProducts';
import { updateProductReducer } from './extraReducers/updateProduct';
import { addImageToProductGalleryReducer } from './extraReducers/sendImageToProductGallery';
import { addNewProductReducer } from './extraReducers/createProduct';
import { deleteProductReducer } from './extraReducers/deleteProduct';
import { getLinesReducer } from './extraReducers/getLines';
import { addNewLineReducer } from './extraReducers/createLine';
import { updateLineReducer } from './extraReducers/updateLine';
import { deleteLineReducer } from './extraReducers/deleteLine';
import { getBrandsReducer } from './extraReducers/getBrands';
import { addNewBrandReducer } from './extraReducers/createBrand';
import { updateBrandReducer } from './extraReducers/updateBrand';
import { deleteBrandReducer } from './extraReducers/deleteBrand';
import { deleteImageToProductGalleryReducer } from './extraReducers/deleteImageToProductGallery';
import { updateProductVideoReducer } from './extraReducers/updateProductVideo';

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateProduct: (state, action: PayloadAction<IProduct>) => {
      const index = state.products.findIndex(product => product.id === action.payload.id);
      state.products[index] = action.payload;
    },
    addImageToProductGallery: (state, action: { payload: { id: string; url: string } }) => {
      state.addImageToProductGallery = { ...action.payload, status: 'idle' };
    },
    deleteImageToProductGallery: (state, action: { payload: { id: string; url: string } }) => {
      state.deleteImageToProductGallery = { ...action.payload, status: 'idle' };
    },
    addNewProduct: (state, action: PayloadAction<IProduct>) => {
      const { brand, line, packingType } = action.payload;
      state.createProduct.productData = {
        ...action.payload,
        brandId: brand.id,
        lineId: line.id,
        packingType: packingType.id,
      };
    },
    deleteProduct: (state, action) => {
      state.productToDelete.id = action.payload;
    },
    resetProductsStatus: state => {
      state.productToDelete.message = '';
      state.updateProduct.message = '';
      state.createProduct.message = '';
    },
    createBrand: (state, action) => {
      state.newBrand.data = action.payload;
    },
    setUpdateBrand: (state, action) => {
      state.updateBrand.data = action.payload;
    },
    deleteBrand: (state, action) => {
      state.brandToDelete.id = action.payload;
    },
    resetBrandsStatus: state => {
      state.brandToDelete.message = '';
      state.updateBrand.message = '';
      state.newBrand.message = '';
    },
    createLine: (state, action) => {
      state.newLine.data = action.payload;
    },
    setUpdateLine: (state, action) => {
      state.updateLine.data = action.payload;
    },
    deleteLine: (state, action) => {
      state.lineToDelete.id = action.payload;
    },
    resetLinesStatus: state => {
      state.lineToDelete.message = '';
      state.updateLine.message = '';
      state.newLine.message = '';
    },
  },
  extraReducers: builder => {
    getProductsReducer(builder);
    updateProductReducer(builder);
    addNewProductReducer(builder);
    deleteProductReducer(builder);
    getLinesReducer(builder);
    addNewLineReducer(builder);
    updateLineReducer(builder);
    getBrandsReducer(builder);
    addNewBrandReducer(builder);
    updateBrandReducer(builder);
    deleteBrandReducer(builder);
    deleteLineReducer(builder);
    addImageToProductGalleryReducer(builder);
    deleteImageToProductGalleryReducer(builder);
    updateProductVideoReducer(builder);
  },
});

export const {
  updateProduct,
  addNewProduct,
  deleteProduct,
  createBrand,
  setUpdateBrand,
  createLine,
  setUpdateLine,
  deleteLine,
  deleteBrand,
  resetBrandsStatus,
  resetProductsStatus,
  resetLinesStatus,
  addImageToProductGallery,
  deleteImageToProductGallery,
} = productsSlice.actions;

export default productsSlice.reducer;
