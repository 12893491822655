import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../../../models/Cliente';
import httpService from '../../../../services/httpService';
import { ClienteState } from '../store';

export const getClientes = createAsyncThunk('getClientes', httpService);

export const extraClientReducers = (builder: ActionReducerMapBuilder<ClienteState>) => {
  builder.addCase(getClientes.fulfilled, (state, action: PayloadAction<Client[]>) => {
    state.clientes = action.payload;
    state.filteredCLientes = action.payload;
    state.status = 'succeeded';
  });

  builder.addCase(getClientes.pending, (state, action) => {
    state.status = 'pending';
  });

  builder.addCase(getClientes.rejected, (state, action) => {
    state.clientes = [];
    state.status = 'failed';
  });
};
