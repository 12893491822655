import { useGetCatalogData } from 'hooks/useGetCatalogData';
import { IProduct } from 'models/Products';
import { useState, useEffect } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { Gallery } from './Gallery';
import { Information } from './Information';
import { Video } from './Video';

const ProductToViewDefault = {
  id: '',
  name: '',
  imageUrl: '',
  weight: '',
  packingType: {
    id: 0,
    name: '',
  },
  brand: {
    id: '',
    name: '',
  },
  line: {
    id: '',
    name: '',
  },
  description: '',
  videoUrl: '',
  imageGallery: [''],
};

const ViewProduct = () => {
  useGetCatalogData();
  const history = useHistory();
  const { products } = useAppSelector(state => state.products);
  const { id }: { id: string } = useParams();
  const [productToView, setProductToView] = useState<IProduct>(ProductToViewDefault);

  // get product by id
  useEffect(() => {
    if (products.length) {
      const index = products.findIndex(product => product.id === id);
      setProductToView(products[index]);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, products]);

  return (
    <Container>
      <ArrowBack onClick={() => history.push('/catalog')} />
      <Content>
        <Title>Información</Title>
        <Information product={productToView} />
      </Content>

      {productToView.imageGallery.length ? (
        <Content>
          <Title>Galería</Title>
          <Gallery imageGallery={productToView.imageGallery} />
        </Content>
      ) : null}

      {productToView.videoUrl?.length ? (
        <Content>
          <Title>Vídeo</Title>
          <Video videoUrl={productToView.videoUrl} poster={productToView.imageUrl} />
        </Content>
      ) : null}
    </Container>
  );
};

export default ViewProduct;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Content = styled.section`
  width: 100%;
  max-width: 800px;
  margin: 0 auto 24px auto;
  padding: 12px;
  border-radius: 12px;
  background: var(--chakra-colors-white-primary);
`;

const Title = styled.h3`
  margin: 0 auto 12px 0;
  color: var(--chakra-colors-gray-table);
  font-size: 1em;
  font-weight: 700;
`;

const ArrowBack = styled(BiArrowBack)`
  font-size: 24px;
  color: var(--chakra-colors-black-primary);
  margin: 20px auto 0 8px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: translateX(-3px);
  }
`;
