import { SalesPerson } from 'models/SalesPersons';
import { object, string, SchemaOf } from 'yup';

const required = 'Campo requerido';

export const salesPersonSchema: SchemaOf<SalesPerson> = object()
  .shape({
    codigoTams: string().notRequired().defined(),
    nombre: string().notRequired().defined(),
    auth0Id: string()
      .nullable(true)
      .required(required)
      .min(20, 'Este no es un valor válido')
      .defined(),
  })
  .defined();
