import { BrandCard } from 'components/Catalog/Brands/BrandCard';
import styled from 'styled-components';
import { IBrand } from '../common';

interface IProps {
  brands: IBrand[];
  viewBrand: (id: string) => void;
}

export const Table = ({ brands, viewBrand }: IProps) => {
  return (
    <Container>
      {brands.map(brand => (
        <BrandCard
          key={brand.id}
          image={brand.image}
          id={brand.id}
          name={brand.name}
          brandSelected={(id: string) => viewBrand(id)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 240px);
  width: auto;
  max-width: 1000px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 130px));
  grid-template-rows: repeat(auto-fill, minmax(162px, 162px));
  column-gap: 12px;
  row-gap: 12px;
`;
