import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: String(process.env.REACT_APP_FIRE_API_KEY),
  authDomain: String(process.env.REACT_APP_FIRE_AUTH_DOMAIN),
  projectId: String(process.env.REACT_APP_FIRE_PROJECT_ID),
  storageBucket: String(process.env.REACT_APP_FIRE_STORAGE_BUCKET),
  messagingSenderId: String(process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID),
  appId: String(process.env.REACT_APP_FIRE_APP_ID),
};

export const app = initializeApp(firebaseConfig);
