import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { ILines } from '../../../../models/Products';
import { IState } from '../common';

export const getLines = createAsyncThunk('lines/getLines', httpService);

export const getLinesReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(getLines.fulfilled, (state, action: PayloadAction<ILines[]>) => {
    state.lines = action.payload;
    state.linesStatus = 'succeeded';
  });

  builder.addCase(getLines.pending, (state, action) => {
    state.linesStatus = 'pending';
  });

  builder.addCase(getLines.rejected, (state, action) => {
    state.linesStatus = 'failed';
  });
};
