import { Button } from '@chakra-ui/button';
import { FormControl } from '@chakra-ui/form-control';
import { Text } from '@chakra-ui/layout';
import { StyledFormLabel } from 'components/common/CustomInput/CustomInput';
import { IPosition, Map } from 'components/common/Map/Map';
import { setModifiedPosition, setViewMap } from 'features/ClientProfile/store/store';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { SearchAddress } from './SearchAddress/searchAddress';

interface IStoresMap {
  editing: boolean;
}

export const StoresMap = ({ editing: formEditing }: IStoresMap) => {
  const [mapEditing, setMapEditing] = useState(false);
  const {
    currentStore,
    map: { modifiedLocalization, viewMap },
    action,
  } = useAppSelector(state => state.clientDetails.stores);
  useEffect(() => {
    if (action === 'updating') setMapEditing(false);
    if (action === 'creating') setMapEditing(formEditing);
  }, [formEditing, action]);

  const dispatch = useDispatch();

  const setClicketPosition = useCallback(
    (value: IPosition) => {
      String(value.lat).length > 0 && dispatch(setModifiedPosition(value));
    },
    [dispatch]
  );

  const setMapView = useCallback(
    (value: IPosition) => {
      String(value.lat).length > 0 && dispatch(setViewMap(value));
    },
    [dispatch]
  );

  const setInitPos: PositionCallback = useCallback(
    position => {
      const { latitude, longitude } = position.coords;
      setMapView({
        lat: latitude,
        lng: longitude,
      });
      setClicketPosition({
        lat: latitude,
        lng: longitude,
      });
    },
    [setClicketPosition, setMapView]
  );

  return (
    <TwoCols>
      <FormControl position='relative' isRequired={mapEditing}>
        <StyledFormLabel inactive={mapEditing ? true : false} color='blue.400'>
          Ubicación
        </StyledFormLabel>
        <SearchAddress mapEditing={mapEditing} />
        <Map
          action={action}
          setClicketPosition={setClicketPosition}
          clicketPosition={modifiedLocalization}
          editing={mapEditing}
          autoLocalization={viewMap as IPosition}
          onLoad={() => {
            if (action === 'updating')
              setMapView({
                lat: Number(currentStore.latitude),
                lng: Number(currentStore.longitude),
              });
            if (action === 'creating') navigator.geolocation.getCurrentPosition(setInitPos);
          }}
        />
        <MapEditContainer>
          {!mapEditing && action === 'updating' && (
            <Button
              h={25}
              bg='yellow.primary'
              onClick={() => setMapEditing(true)}
              _hover={{ backgroundColor: 'var(--chakra-colors-yellow-primaryHover)' }}
              _active={{ backgroundColor: 'var(--chakra-colors-yellow-primaryHover)' }}
            >
              Editar Ubicación
            </Button>
          )}
          {(mapEditing || action === 'creating') && (
            <Text textAlign='center' color='gray.500'>
              Mueva el mapa arrastrando con el dedo, para agregar una marca dé un tap en el lugar
              que desea
            </Text>
          )}
        </MapEditContainer>
      </FormControl>
    </TwoCols>
  );
};

const TwoCols = styled.div`
  grid-column: 1 / -1;
`;

const MapEditContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
`;
