import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { SalesSettingsState } from '../store';

export const updateSalesPerson = createAsyncThunk('update/SalesPerson', httpService);

export const updatePersonReducer = (builder: ActionReducerMapBuilder<SalesSettingsState>) => {
  builder.addCase(updateSalesPerson.fulfilled, state => {
    if (!state.salesPersons) return;

    const modifiedIndex = state.salesPersons.findIndex(
      sp => sp.codigoTams === state.formModal.selectedSalesPerson.codigoTams
    );

    if (state.salesPersons[modifiedIndex].auth0Id === state.formModal.updatedSalesPerson.auth0Id) {
      state.status = 'failed';
      state.message = 'El valor del id debe no puede ser igual al que ya estaba';
      return;
    }

    state.salesPersons[modifiedIndex] = state.formModal.updatedSalesPerson;
    state.message = 'Actualizado con éxito';
    state.status = 'succeeded';
  });

  builder.addCase(updateSalesPerson.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(updateSalesPerson.rejected, state => {
    state.message = 'No se pudo Actualizar, revise su conexión';
    state.status = 'failed';
  });
};
