import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  Skeleton,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

interface ICustomInput extends FormControlProps {
  isRequired?: boolean;
  label: string;
  errors: DeepMap<any, FieldError>;
  type?: string;
  register: UseFormRegister<any>;
  name: any;
  disabled?: boolean;
  editing?: boolean;
  loading?: boolean;
}

export const CustomInput = ({
  isRequired,
  label,
  errors,
  name,
  type = 'text',
  register,
  isReadOnly,
  editing = false,
  placeholder,
  loading,
  ...rest
}: ICustomInput) => {
  const InputSkeleton = () => {
    return <Skeleton h={10} />;
  };

  return (
    <FormControl isRequired={isRequired && editing} {...rest}>
      <StyledFormLabel
        color='blue.500'
        fontWeight='semibold'
        marginBottom='0'
        inactive={!isReadOnly ? editing : false}
      >
        {label}
      </StyledFormLabel>
      {!loading && (
        <StyledInput
          {...register(name)}
          isReadOnly={editing ? isReadOnly : true}
          editing={editing}
          placeholder={placeholder}
          type={type}
          title=''
          key={name}
        />
      )}
      {loading && <InputSkeleton />}
      {errors[name] && (
        <FormHelperText
          fontSize=' 14px'
          fontWeight='600'
          color='rgba(230, 55, 70, 1)'
          marginTop='0'
        >
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const StyledInput = styled(Input)`
  background-color: ${({ editing }: { editing: boolean }) => (!editing ? 'white' : '#f5f8fa')};
  color: inherit;
  border: 1px solid #f0f0f0;
  box-shadow: none;
  padding: 0 4px;

  :read-only {
    opacity: 1;
    cursor: auto;
    color: ${({ editing }: { editing: boolean }) =>
      editing ? 'var(--chakra-colors-gray-table)' : 'black'};
    background-color: #fff;
  }

  :focus,
  :hover {
    border: 1px solid #f0f0f0;
    box-shadow: none;
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  color: ${({ inactive }: { inactive: boolean }) =>
    inactive ? '#109CF1' : 'var(--chakra-colors-gray-table)'};
  :read-only {
    opacity: 1;
    cursor: auto;
  }
`;
