import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFile } from 'services/deleteImage';
import httpService, { IUseFetch } from 'services/httpService';
import { ClienteProfileState } from '../store';

interface IDeleteImage {
  firebaseImageUrl: string;
  idImage: string;
}

export const deleteImage = createAsyncThunk('delete/image', async (params: IUseFetch) => {
  const { body, ...rest } = params;
  const { firebaseImageUrl, idImage } = body as IDeleteImage;
  const { isDeleted, message } = await httpService({ ...rest });
  if (isDeleted) await deleteFile(firebaseImageUrl);
  return { isDeleted, message, idImage };
});

export const deleteImageReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(deleteImage.fulfilled, (state, action) => {
    const { idImage, isDeleted, message } = action.payload;

    if (!isDeleted) {
      state.message = message || 'No se pudo eliminar la imagen';
      state.stores.status = 'failed';
      return;
    }

    const imageToDelIndex = state.stores.currentStore.images.findIndex(
      image => image.id === idImage
    );
    state.stores.currentStore.images.splice(imageToDelIndex, 1);

    const i = state.clientDetails.sucursales.findIndex(
      sucursal => sucursal.codigo === state.stores.currentStore.codigo
    );

    state.clientDetails.sucursales.splice(i, 1, state.stores.currentStore);

    state.message = 'La imagen se elimino con correcamente';
    state.stores.status = 'succeeded';
  });

  builder.addCase(deleteImage.pending, state => {
    state.stores.status = 'pending';
  });

  builder.addCase(deleteImage.rejected, state => {
    state.message = 'No se pudo guardar la imagen, revisa tu conexión';
    state.stores.status = 'failed';
  });
};
