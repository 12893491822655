import { Box, Circle } from '@chakra-ui/layout';
import { BiPlus } from 'react-icons/bi';
import styled from '@emotion/styled';
import Icon from '@chakra-ui/icon';
import { IoMdTrash } from 'react-icons/io';

interface IImageCard {
  src?: string;
  onClick: () => void;
  onDelete?: () => void;
}

export const ImageCard = ({ src, onClick, onDelete }: IImageCard) => {
  return (
    <Box
      display='flex'
      w={200}
      h={150}
      position='relative'
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      justifyContent='center'
      cursor='pointer'
      onClick={onClick}
    >
      {src ? (
        <>
          <img src={src} alt='storage' />
          <DeleteBtn
            size={30}
            bgColor='red.400'
            cursor='pointer'
            onClick={e => {
              e.stopPropagation();
              onDelete && onDelete();
            }}
          >
            <Icon color='white' as={IoMdTrash} />
          </DeleteBtn>
        </>
      ) : (
        <Circle size={30} alignSelf='center'>
          <BiPlus />
        </Circle>
      )}
    </Box>
  );
};

const DeleteBtn = styled(Circle)`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;
