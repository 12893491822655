import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendNewBrand = createAsyncThunk('brands/addNewBrand', httpService);

export const addNewBrandReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendNewBrand.fulfilled, (state, action) => {
    state.brands.unshift({ ...state.newBrand.data, id: action.payload });
    state.newBrand.status = 'succeeded';
    state.newBrand.message = 'Marca creada';
  });

  builder.addCase(sendNewBrand.pending, state => {
    state.newBrand.status = 'pending';
  });

  builder.addCase(sendNewBrand.rejected, state => {
    state.newBrand.status = 'failed';
    state.newBrand.message = 'Error al crear marca';
  });
};
