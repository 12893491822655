import { GoogleMap, Marker } from '@react-google-maps/api';
import { CSSProperties } from 'styled-components';

export interface IPosition {
  lat: number;
  lng: number;
}

export interface IMap {
  clicketPosition: IPosition | undefined;
  setClicketPosition: (value: IPosition) => void;
  action: 'creating' | 'updating' | 'reset';
  editing: boolean;
  autoLocalization: IPosition;
  onLoad: (map: any) => void | Promise<void>;
}

const containerStyle: CSSProperties = {
  width: '100%',
  height: '300px',
  margin: 'auto',
  borderRadius: '12px',
};

export const Map = ({
  clicketPosition,
  setClicketPosition,
  editing,
  autoLocalization,
  onLoad,
}: IMap) => {
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={autoLocalization}
      zoom={15}
      options={{
        gestureHandling: editing ? 'greedy' : 'none',
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
      }}
      onClick={e => {
        editing && setClicketPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      }}
      onLoad={onLoad}
    >
      <Marker position={clicketPosition} />
    </GoogleMap>
  );
};
