import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { ClienteProfileState } from '../store';

export const createStore = createAsyncThunk('create/clientStores', httpService);

export const createStoreReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(createStore.fulfilled, (state, action) => {
    if (!action.payload) {
      state.message = 'No se pudo crear la sucursal, revisa tu conexión';
      state.stores.status = 'failed';
      return;
    }
    state.clientDetails.sucursales.push({
      ...state.stores.modifiedStore,
      codigo: state.stores.modifiedStore.id,
    });
    state.stores.existSucursal = true;
    state.message = 'Se ha creado una nueva sucursal con éxito';
    state.stores.status = 'succeeded';
    state.stores.currentStore = {
      ...state.stores.modifiedStore,
      codigo: state.stores.modifiedStore.id,
    };
    state.stores.action = 'updating';
  });

  builder.addCase(createStore.pending, state => {
    state.stores.status = 'pending';
  });

  builder.addCase(createStore.rejected, state => {
    state.message = 'No se pudo crear la sucursal, revisa tu conexión';
    state.stores.status = 'failed';
  });
};
