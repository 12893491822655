import { Text } from '@chakra-ui/layout';
import { getGeolocation } from 'features/ClientProfile/store/extraReducers/getGeolocation';
import { ChangeEvent, useEffect, useState } from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import { MdLocationOff } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import usePlacesAutocomplete from 'use-places-autocomplete';

export const SearchAddress = ({ mapEditing }: { mapEditing: boolean }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  const dispatch = useDispatch();

  const [viewAddress, setViewAddress] = useState(false);

  useEffect(() => {
    if (status === 'OK') {
      setViewAddress(true);
    }
  }, [status]);

  const handleSelect = (searchValue: string): void => {
    setValue(searchValue, false);
    setViewAddress(false);
    Number(searchValue?.length) > 1 && dispatch(getGeolocation(String(searchValue)));
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    if (e.target.value.length) {
      setViewAddress(true);
    }
  };

  return (
    <OptionsContainer>
      <Search
        placeholder='Buscar ubicación'
        value={value}
        onChange={handleInput}
        disabled={!mapEditing}
      />

      <OptionsList active={viewAddress && value.length ? true : false}>
        {data.length && value.length ? (
          data.map(({ place_id, description }: any) => (
            <Option key={place_id} onClick={() => handleSelect(description)}>
              <AddressIcon />
              <Text textAlign='start'>{description}</Text>
            </Option>
          ))
        ) : (
          <Option>
            <NoAddressIcon />
            <Text color='negative' textAlign='start'>
              Al parecer no encuentro esa ubicación
            </Text>
          </Option>
        )}
      </OptionsList>
    </OptionsContainer>
  );
};
const Search = styled.input`
  width: 100%;
  margin: 0 auto 8px auto;
  padding: 10px 16px;
  border-radius: 8px;
  background: #f5f8fa;
  font-size: 1em;
  border: 1px solid #f0f0f0;
  outline: none;

  &:placeholder {
    color: var(--chakra-colors-gray-primary);
  }

  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    `background: initial;
    
    `};
`;

const OptionsContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const fadeIn = keyframes`
  from {
    transform: translateY(-40px) scale(.5);
  }

  to {
    transform: translateY(2px) scale(1);
  }
`;

const OptionsList = styled.div`
  width: 100%;
  position: absolute;
  padding: 4px;
  overflow: auto;
  z-index: 99999;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: #ffff;
  display: none;
  max-height: 400%;
  overflow-y: auto;
  min-height: 50px;

  ${({ active }: { active: boolean }) =>
    active &&
    css`
      display: block;
      animation: ${fadeIn} 0.3s forwards;
    `};
`;

const Option = styled.div`
  width: 100%;
  background: #ffff;
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  margin: 0 auto 4px auto;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-template-columns: 16 auto;
  column-gap: 8px;
  align-items: center;

  &:hover {
    background: #fefdec;
  }
`;

const NoAddressIcon = styled(MdLocationOff)`
  font-size: 1em;
  width: 16px;
  color: var(--chakra-colors-red-negative);
`;

const AddressIcon = styled(IoLocationSharp)`
  font-size: 1em;
  width: 16px;
  color: var(--chakra-colors-red-negative);
`;
