import { Flex, IconButton, IconButtonProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FlexProps } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import { FaPen, FaSave } from 'react-icons/fa';

interface IControls extends FlexProps {
  setEditing: (value: boolean) => void;
  editing?: boolean;
  onEditCancel: () => void;
  onSave: () => void;
}

type TButton = Omit<IconButtonProps, 'aria-label'>;

interface IButton extends TButton {
  onClick: () => void;
}

export const EditControls = ({ setEditing, editing, onEditCancel, onSave }: IControls) => {
  return (
    <StyledFlex>
      {!editing && <EditButton aria-label='' onClick={() => setEditing(true)} />}
      {editing && (
        <>
          <SaveButton onClick={onSave} />
          <CancelButton onClick={onEditCancel} />
        </>
      )}
    </StyledFlex>
  );
};

export const EditButton = ({ onClick, ...rest }: IButton) => {
  return (
    <IconButton
      bg='yellow.primary'
      size='sm'
      rounded='3xl'
      aria-label='Edit icon'
      icon={<FaPen />}
      onClick={onClick}
      _hover={{ backgroundColor: 'var(--chakra-colors-yellow-primaryHover)' }}
      _active={{ backgroundColor: 'var(--chakra-colors-yellow-primaryHover)' }}
      _focus={{ border: 'none' }}
      {...rest}
    />
  );
};

export const CancelButton = ({ onClick, ...rest }: IButton) => {
  return (
    <IconButton
      onClick={onClick}
      color='white.primary'
      _selected={{}}
      bg='red.300'
      size='sm'
      rounded='3xl'
      aria-label='Cancel icon'
      icon={<CgClose />}
      animation='ease-in'
      _hover={{ backgroundColor: 'var(--chakra-colors-red-negativeHover)' }}
      _active={{ backgroundColor: 'var(--chakra-colors-red-negativeHover)' }}
      _focus={{ border: 'none' }}
      {...rest}
    />
  );
};

export const SaveButton = ({ onClick, ...rest }: IButton) => {
  return (
    <IconButton
      onClick={onClick}
      color='white'
      bg='green.primary'
      _hover={{ backgroundColor: 'var(--chakra-colors-green-primaryHover)' }}
      _active={{ backgroundColor: 'var(--chakra-colors-green-primaryHover)' }}
      _focus={{ border: 'none' }}
      size='sm'
      rounded='3xl'
      aria-label='Save icon'
      icon={<FaSave />}
      animation='ease-in'
      {...rest}
    />
  );
};

const StyledFlex = styled(Flex)`
  position: absolute;
  width: 100px;
  justify-content: space-evenly;
  right: 0;
  top: 8px;
`;
