import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendImageToProductGallery = createAsyncThunk(
  'catalog/addImageToProductGallery',
  httpService
);

export const addImageToProductGalleryReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendImageToProductGallery.fulfilled, state => {
    const index = state.products.findIndex(
      product => product.id === state.addImageToProductGallery.id
    );
    state.products[index].imageGallery.unshift(state.addImageToProductGallery.url);
    state.addImageToProductGallery.status = 'succeeded';
  });

  builder.addCase(sendImageToProductGallery.pending, state => {
    state.addImageToProductGallery.status = 'pending';
  });

  builder.addCase(sendImageToProductGallery.rejected, state => {
    state.addImageToProductGallery.status = 'failed';
  });
};
