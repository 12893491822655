import styled from 'styled-components';
import { AgTable } from 'components/common/Table/Table';
import { useHistory } from 'react-router-dom';
import { Columns } from './columns';

interface IProps {
  id: any;
  name: string;
  imageUrl: string;
  weight: string;
  packingType: any;
  brand: any;
  line: any;
}

export const Products = ({ products }: { products: IProps[] }) => {
  const history = useHistory();

  return (
    <Container>
      <AgTable
        loading={false}
        rowData={products}
        gridOptions={{ columnDefs: Columns }}
        onRowClicked={event => history.push(`/settings/viewProduct/${event.data.id}`)}
        pagination={false}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 1000px;
  height: 250px;
  margin: auto;
`;
