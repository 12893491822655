import { UseCustomDispatch } from '../../../hooks/useCustomDispatch';
import { updateClientInfo } from '../../../features/ClientProfile/store/extraReducers/UpdateCLientInfo';
import { Inputs } from './ClientInfo';

interface Idata extends Inputs {
  idTams: string;
}

export const updateCLientInfoAction = (
  customDispatch: (params: UseCustomDispatch) => void,
  data: Idata
) => {
  const { direccion, contacto, correoPrincipal, telefono1, idTams } = data;
  const body = {
    idTams,
    direccion,
    contacto,
    correoPrincipal,
    telefono1,
  };
  customDispatch({
    asyncThuckFuction: updateClientInfo,
    endPoint: 'api/Clientes/UpdateCliente',
    body,
    method: 'PUT',
  });
};
