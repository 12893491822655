import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IBrands } from '../../../../models/Products';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const updateBrand = createAsyncThunk('brands/updateBrand', httpService);

export const updateBrandReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(updateBrand.fulfilled, state => {
    const index = state.brands.findIndex(
      (brand: IBrands) => brand.id === state.updateBrand.data.id
    );
    state.brands[index] = state.updateBrand.data;
    state.updateBrand.status = 'succeeded';
    state.updateBrand.message = 'Marca actualizada';
  });

  builder.addCase(updateBrand.pending, state => {
    state.updateBrand.status = 'pending';
  });

  builder.addCase(updateBrand.rejected, state => {
    state.updateBrand.status = 'failed';
    state.updateBrand.message = 'Error al actualizar marca';
  });
};
