import { ClienteState, Statistics } from '../store';

export const calcStatisticsReducer = (state: ClienteState) => {
  const initialValue = { activos: 0, inactivos: 0, total: state.clientes.length } as Statistics;

  state.statistics = state.clientes.reduce((acc, val) => {
    acc = {
      ...acc,
      activos: (val.estado === 'A' && acc.activos + 1) || acc.activos,
      inactivos: (val.estado === 'I' && acc.inactivos + 1) || acc.inactivos,
    };
    return acc;
  }, initialValue);
};
