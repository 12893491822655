import Icon from '@chakra-ui/icon';
import { Circle } from '@chakra-ui/layout';
import { TiArrowSync } from 'react-icons/ti';
import styled, { keyframes } from 'styled-components';

export const SwitchButton = ({ onClick }: { onClick: () => void }) => (
  <SwitchCameraButton size='40px' onClick={onClick}>
    <Icon h={6} w={6} as={TiArrowSync} color='white' />
  </SwitchCameraButton>
);

export const ScreenshotButton = ({ onClick }: { onClick: () => void }) => (
  <ScreenShotButton cursor='pointer' backgroundColor='whiteAlpha.400' size='60px' onClick={onClick}>
    <Pulsar />
  </ScreenShotButton>
);

export const ScreenShotButton = styled(Circle)`
  position: absolute;
  margin-left: calc(50% - 30px);
  bottom: 1rem;
`;

const pulsar = keyframes`
  0% {
    transform:scale(0) ;
  }
  20% {
    transform:scale(1) ;
  }
`;

const Pulsar = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--chakra-colors-blackAlpha-200);
  border-radius: 50%;
  animation: 3s ${pulsar} ease infinite;
  position: absolute;
`;

const SwitchCameraButton = styled(Circle)`
  position: absolute;
  background-color: var(--chakra-colors-blackAlpha-700);
  right: 2rem;
  bottom: 1.3rem;
  cursor: pointer;
`;
