import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';
import { ClientesTable } from '../../components/Clientes/ClientesTable/ClientesTable';
import { ClientStats } from '../../components/Clientes/ClientStats/ClientStats';
import { Container } from 'components/common/Container/Container';

export const Clientes = () => {
  return (
    <Container>
      <Section>
        <ClientStats />
        <ClientesTable />
      </Section>
    </Container>
  );
};

const Section = styled(Flex)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;
