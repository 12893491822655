import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ClientDetails } from '../../../../models/ClientDetails';
import httpService from '../../../../services/httpService';
import { ClienteProfileState } from '../store';

export const getClientDetails = createAsyncThunk('get/ClientDetalils', httpService);

export const getClientReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(getClientDetails.fulfilled, (state, action: PayloadAction<ClientDetails>) => {
    if (!action?.payload?.cliente) {
      state.message = 'Este cliente no existe';
      return;
    }
    const {
      cartera,
      codigoTams,
      contacto,
      correoPrincipal,
      direccion,
      giro,
      listaPrecio,
      nombre,
      rtn,
      telefono1,
    } = action?.payload?.cliente;

    state.clientDetails = {
      ...action.payload,
      cliente: {
        cartera,
        codigoTams,
        contacto,
        correoPrincipal,
        direccion,
        giro,
        listaPrecio,
        nombre,
        rtn,
        telefono1,
      },
      contactos: action.payload.contactos.map(rowData => ({
        idCliente: rowData.idCliente,
        id: rowData.id,
        nombre: rowData.nombre,
        puesto: rowData.puesto,
        correo: rowData.email,
        telefono: rowData.telefono,
        celular: rowData.celular,
      })),
    };
    state.status = 'succeeded';
  });

  builder.addCase(getClientDetails.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(getClientDetails.rejected, state => {
    state.message = 'No se pudo obtener informacion, revise su conexión';
    state.status = 'failed';
  });
};
