export interface IUseFetch {
  endPoint: string;
  token?: Promise<string>;
  body?: {};
  method?: 'POST' | 'GET' | 'PUT' | 'DELETE';
}

const httpService = async ({ endPoint, token, body, method }: IUseFetch) => {
  const api: string = String(process.env.REACT_APP_API_URL);
  const headers = {
    Authorization: `Bearer ${await token}`,
    'Content-Type': 'application/json',
  };

  const config: RequestInit = {
    method: method ? method : body ? 'POST' : 'GET',
    headers: {
      ...headers,
    },
  };

  if (body) config.body = JSON.stringify(body);

  const response = await fetch(`${api}${endPoint}`, config);
  return await response.json();
};

export default httpService;
