import { ActionReducerMapBuilder, createAsyncThunk, current } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const removeImageToProductGallery = createAsyncThunk(
  'catalog/deleteImageToProductGallery',
  httpService
);

export const deleteImageToProductGalleryReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(removeImageToProductGallery.fulfilled, state => {
    const allProducts = current(state.products);
    const index = allProducts.findIndex(
      product => product.id === state.deleteImageToProductGallery.id
    );
    state.products[index].imageGallery = allProducts[index].imageGallery.filter(
      image => image !== state.deleteImageToProductGallery.url
    );
    state.deleteImageToProductGallery.status = 'succeeded';
  });

  builder.addCase(removeImageToProductGallery.pending, state => {
    state.deleteImageToProductGallery.status = 'pending';
  });

  builder.addCase(removeImageToProductGallery.rejected, state => {
    state.deleteImageToProductGallery.status = 'failed';
  });
};
