import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import Layout from './components/common/Layout/Layout';
import ProtectedRoute from './auth/protected-route';

// Views
import { Inicio } from './features/Inicio/Inicio';
import { Logout } from './features/Logout/Logout';
import { Clientes } from './features/Clientes/Clientes';
import Catalog from './features/Catalog/Catalog';
import Settings from './features/Settings/Settings';
import Lines from './features/Settings/Catalog/Lines/Lines';
import Brands from './features/Settings/Catalog/Brands/Brands';
import Products from './features/Settings/Catalog/Products/Products';
import ViewAndEditProduct from './components/settings/catalog/Products/ViewAndEditProduct/ViewAndEditProduct';
import CreateProduct from './components/settings/catalog/Products/CreateProduct/CreateProduct';
import ViewProduct from './components/Catalog/ViewProduct/ViewProduct';

import { useCustomDispatch } from './hooks/useCustomDispatch';
import { getClientes } from './features/Clientes/store/extraReducers/getClientesReducer';
import ClientProfile from './features/ClientProfile/ClientProfile';
import { StoresInfo } from 'components/ClientProfile/StoresInfo/StoresInfo';

export const App = () => {
  const customDispath = useCustomDispatch();

  useEffect(() => {
    customDispath({
      asyncThuckFuction: getClientes,
      endPoint: 'api/Clientes/GetClientes',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Switch>
      <ProtectedRoute path='/logout' exact={true} component={Logout} />
      <Layout>
        <ProtectedRoute path='/' exact={true} component={Inicio} />
        <ProtectedRoute path='/clientes' exact={true} component={Clientes} />
        <ProtectedRoute path='/catalog' exact={true} component={Catalog} />
        <ProtectedRoute path='/catalog/viewProduct/:id' exact={true} component={ViewProduct} />
        <ProtectedRoute path='/settings' exact={true} component={Settings} />
        <ProtectedRoute path='/settings/products' exact={true} component={Products} />
        <ProtectedRoute path='/settings/lines' exact={true} component={Lines} />
        <ProtectedRoute path='/settings/brands' exact={true} component={Brands} />
        <ProtectedRoute
          path='/settings/viewProduct/:id'
          exact={true}
          component={ViewAndEditProduct}
        />
        <ProtectedRoute path='/createProduct' exact={true} component={CreateProduct} />
        <ProtectedRoute path='/clientes/:id' exact={true} component={ClientProfile} />
        <ProtectedRoute path='/clientes/:id/tiendas/:idStore' exact={true} component={StoresInfo} />
      </Layout>
    </Switch>
  );
};
