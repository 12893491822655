import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { ClienteProfileState } from '../store';

export const updateClientInfo = createAsyncThunk('update/ClientInfo', httpService);

export const updateClientInfoReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(updateClientInfo.fulfilled, (state, action) => {
    state.clientDetails.cliente = state.modifiedClientInfo;
    state.message = 'Actualizado con éxito';
    state.status = 'succeeded';
  });

  builder.addCase(updateClientInfo.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(updateClientInfo.rejected, (state, action) => {
    state.message = 'No se pudo actualizar los datos, revisa tu conexión';
    state.status = 'failed';
  });
};
