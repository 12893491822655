import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService, { IUseFetch } from 'services/httpService';
import { IUploadImage, uploadBase64 } from 'services/uploadBase64';
import { ClienteProfileState } from '../store';

interface ISaveImage {
  customerId: number;
  storeId: number;
  imageUrl: string;
  firebase: IUploadImage;
}

export const saveImage = createAsyncThunk('save/image', async (params: IUseFetch) => {
  const { firebase, ...rest } = params.body as ISaveImage;
  const url = await uploadBase64(firebase);
  const { imageId }: { imageId: string } = await httpService({
    ...params,
    body: { ...rest, imageUrl: url },
  } as IUseFetch);
  return { url, imageId };
});

export const saveImageReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(saveImage.fulfilled, (state, action) => {
    if (action.payload.imageId.length <= 0) {
      state.message = 'No se pudo guardar la imagen';
      state.stores.status = 'failed';
      return;
    }
    state.stores.currentStore.images = [
      ...state.stores.currentStore?.images,
      {
        id: action.payload.imageId,
        imageUrl: action.payload.url,
      },
    ];

    const i = state.clientDetails.sucursales.findIndex(
      sucursal => sucursal.codigo === state.stores.currentStore.codigo
    );

    state.clientDetails.sucursales[i] = state.stores.currentStore;

    state.message = 'La imagen se guardo con éxito';
    state.stores.status = 'succeeded';
  });

  builder.addCase(saveImage.pending, state => {
    state.stores.status = 'pending';
  });

  builder.addCase(saveImage.rejected, state => {
    state.message = 'No se pudo guardar la imagen, revisa tu conexión';
    state.stores.status = 'failed';
  });
};
