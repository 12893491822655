import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesPerson } from 'models/SalesPersons';
import { RootState } from 'store/store';
import { getSalesPersonsReducer } from './extraReducers/GetSalesPersons';
import { updatePersonReducer } from './extraReducers/UpdateSalesPerson';

export interface SalesSettingsState {
  status: StatusApp.Status;
  message: string;
  salesPersons?: SalesPerson[];
  formModal: {
    selectedSalesPerson: SalesPerson;
    showModal: boolean;
    updatedSalesPerson: SalesPerson;
  };
}

const initialState: SalesSettingsState = {
  status: 'succeeded',
  message: '',
  salesPersons: undefined,
  formModal: {
    selectedSalesPerson: {} as SalesPerson,
    showModal: false,
    updatedSalesPerson: {} as SalesPerson,
  },
};

export const salesPersonSlice = createSlice({
  name: 'salesPerson',
  initialState,
  reducers: {
    setCurrentSalesPerson: (state, action: PayloadAction<{ codigoTams: string }>) => {
      if (!state.salesPersons) return;
      const selectedSalesPerson = state.salesPersons.filter(
        persons => persons.codigoTams === action.payload.codigoTams
      )[0];

      state.formModal.selectedSalesPerson = selectedSalesPerson;
      state.formModal.showModal = true;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.formModal.showModal = action.payload;
    },
    setUpdatedSalesPerson: (state, action: PayloadAction<SalesPerson>) => {
      state.formModal.updatedSalesPerson = action.payload;
    },
    setMessageEmpty: state => {
      state.message = '';
    },
  },
  extraReducers: builder => {
    getSalesPersonsReducer(builder);
    updatePersonReducer(builder);
  },
});

export const { setCurrentSalesPerson, setShowModal, setUpdatedSalesPerson, setMessageEmpty } =
  salesPersonSlice.actions;

export const selectClientProfile = (state: RootState) => state.clientDetails;

export const salesPersonReducer = salesPersonSlice.reducer;
