import { object, string } from 'yup';

const required = 'Campo requerido';

export const ClientProfileSchema = object()
  .shape({
    nombre: string().required(required).defined(),
    contacto: string().required(required).defined(),
    correoPrincipal: string().required(required).email('Debe ser un email').defined(),
    direccion: string().required(required).defined(),
    cartera: string().defined(),
    giro: string().defined().nullable(true).notRequired(),
    listaPrecio: string().defined().nullable(true).notRequired(),
    rtn: string().defined().nullable(true).notRequired(),
    telefono1: string().defined().nullable(true).notRequired(),
  })
  .defined();
