import { IProduct, IProductBackend, IBrands, ILines } from '../../../models/Products';

export interface IState {
  products: IProduct[];
  status: StatusApp.Status;
  updateProduct: {
    status: StatusApp.Status;
    message: string;
  };
  createProduct: {
    productData: IProductBackend;
    status: StatusApp.Status;
    id: string;
    message: string;
  };
  addImageToProductGallery: {
    id: string;
    url: string;
    status: StatusApp.Status;
  };
  deleteImageToProductGallery: {
    id: string;
    url: string;
    status: StatusApp.Status;
  };
  productToDelete: {
    id: string;
    status: StatusApp.Status;
    message: string;
  };
  brands: IBrands[];
  brandsStatus: StatusApp.Status;
  brandToDelete: {
    id: string;
    status: StatusApp.Status;
    message: string;
  };
  newBrand: {
    data: {
      name: string;
      image: string;
    };
    status: StatusApp.Status;
    message: string;
  };
  updateBrand: {
    data: {
      name: string;
      id: string;
      image: string;
    };
    status: StatusApp.Status;
    message: string;
  };
  lines: ILines[];
  linesStatus: StatusApp.Status;
  lineToDelete: {
    id: string;
    status: StatusApp.Status;
    message: string;
  };
  newLine: {
    data: {
      name: string;
    };
    status: StatusApp.Status;
    message: string;
  };
  updateLine: {
    data: {
      name: string;
      id: string;
    };
    status: StatusApp.Status;
    message: string;
  };
}

export const initialState: IState = {
  products: [] as IProduct[],
  status: 'idle',
  updateProduct: {
    status: 'idle',
    message: '',
  },
  createProduct: {
    productData: {} as IProductBackend,
    status: 'idle',
    id: '',
    message: '',
  },
  productToDelete: {
    id: '',
    status: 'idle',
    message: '',
  },
  addImageToProductGallery: {
    id: '',
    url: '',
    status: 'idle',
  },
  deleteImageToProductGallery: {
    id: '',
    url: '',
    status: 'idle',
  },
  brands: [] as IBrands[],
  brandsStatus: 'idle',
  brandToDelete: {
    id: '',
    status: 'idle',
    message: '',
  },
  newBrand: {
    data: { name: '', image: '' },
    status: 'idle',
    message: '',
  },
  updateBrand: {
    data: { name: '', id: '', image: '' },
    status: 'idle',
    message: '',
  },
  lines: [] as ILines[],
  linesStatus: 'idle',
  lineToDelete: {
    id: '',
    status: 'idle',
    message: '',
  },
  newLine: {
    data: { name: '' },
    status: 'idle',
    message: '',
  },
  updateLine: {
    data: { name: '', id: '' },
    status: 'idle',
    message: '',
  },
};

export const PackingType = ['Termoformado', 'Bolsa', 'Bolsa Al Vacio'];
