import { ColDef } from 'ag-grid-community/main';

export const columns: ColDef[] = [
  {
    flex: 1,
    field: 'nombre',
    headerName: 'Nombre',
    sortable: true,
    sortIndex: 1,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'puesto',
    headerName: 'Puesto',
    wrapText: true,
    resizable: true,
    sortIndex: 2,
    autoHeight: true,
    minWidth: 80,
    initialWidth: 150,
  },
  {
    flex: 1,
    field: 'correo',
    headerName: 'Correo',
    sortIndex: 3,
    minWidth: 190,
    wrapText: true,
    cellClass: 'correo',
  },
  {
    flex: 1,
    field: 'telefono',
    headerName: 'Teléfono',
    wrapText: true,
    resizable: true,
    minWidth: 80,
    sortIndex: 4,
    autoHeight: true,
  },
  {
    flex: 1,
    field: 'celular',
    headerName: 'Celular',
    autoHeight: true,
    minWidth: 80,
    resizable: false,
  },
];
