import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { ClienteProfileState } from '../store';

export const createClientContact = createAsyncThunk('create/clientContact', httpService);

export const createContactReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(createClientContact.fulfilled, (state, action) => {
    if (!action.payload) {
      state.message = 'No se pudo crear el contacto, revisa tu conexión';
      state.status = 'failed';
      return;
    }
    state.clientDetails.contactos.push(state.modifyContact);
    state.message = 'Se ha creado un nuevo contacto con éxito';
    state.status = 'succeeded';
  });

  builder.addCase(createClientContact.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(createClientContact.rejected, (state, action) => {
    state.message = 'No se pudo crear el contacto, revisa tu conexión';
    state.status = 'failed';
  });
};
