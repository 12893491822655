import { Button } from '@chakra-ui/react';
import { RowClickedEvent } from 'ag-grid-community';
import { CustomAlert } from 'components/common/Alert/Alert';
import { TitleIcon } from 'components/common/TitleIcon/TitleIcon';
import { UseCustomDispatch, useCustomDispatch } from 'hooks/useCustomDispatch';
import { Contactos } from 'models/ClientDetails';
import { useState } from 'react';
import { MdContactPhone } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { searchMaxNumber } from 'utils/maxNumber';
import { createClientContact } from '../../../features/ClientProfile/store/extraReducers/CreateContact';
import { updateClientContact } from '../../../features/ClientProfile/store/extraReducers/UpdateClientContact';
import { setMessageEmpty, setModifiedContact } from '../../../features/ClientProfile/store/store';
import { columns } from './columns';
import { ContacsTable } from './ContacsTable/ContacsTable';
import { ModalForm, formValues } from './ModalForm/ModalForm';

export interface IModal {
  value: boolean;
  type: 'PUT' | 'POST';
}

export const CLientContact = () => {
  const {
    status,
    message,
    clientDetails: { contactos, cliente },
  } = useAppSelector(state => state.clientDetails);
  const [currentContact, setCurrentContact] = useState<Contactos>({} as Contactos);
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [showModal, setShowModal] = useState<IModal>({
    value: false,
    type: 'POST',
  });

  const onRowClicked = (event: RowClickedEvent) => {
    const contacto = contactos.find(contact => contact.id === event.data.id);
    setCurrentContact(contacto as Contactos);
    setShowModal({ value: true, type: 'PUT' });
  };

  const createContact = () => {
    setCurrentContact({} as Contactos);
    setShowModal({ type: 'POST', value: true });
  };

  const onSuccesSave = (values: formValues) => {
    const actions: { [key: string]: UseCustomDispatch } = {
      POST: {
        asyncThuckFuction: createClientContact,
        endPoint: 'api/Clientes/AddContacto',
        body: {
          ...values,
          idCliente: String(cliente.codigoTams),
          id: searchMaxNumber(contactos.map(contact => contact.id)) + 1,
        },
        method: showModal.type,
      },
      PUT: {
        asyncThuckFuction: updateClientContact,
        endPoint: 'api/Clientes/updateContacto',
        body: {
          idCliente: String(cliente.codigoTams),
          id: currentContact.id,
          nombre: values.nombre,
          puesto: values.puesto,
          correo: values.correo,
          telefono: values.telefono,
          celular: values.celular,
        },
        method: showModal.type,
      },
    };
    dispatch(setModifiedContact(actions[showModal.type].body as Contactos));
    customDispatch(actions[showModal.type]);
  };

  const onModalCLose = () => {
    dispatch(setMessageEmpty());
  };

  return (
    <ContactContainer>
      <CustomAlert onModalCLose={onModalCLose} description={message} status={status} />
      <SectionHead>
        <TitleIcon text='Contactos' icon={MdContactPhone} />
        <Button
          bgColor='green.300'
          color='white'
          h={6}
          _hover={{ backgroundColor: 'var(--chakra-colors-green-primaryHover)' }}
          onClick={createContact}
          _active={{ backgroundColor: 'var(--chakra-colors-green-primaryHover)' }}
          _focus={{ border: 'none' }}
        >
          Crear
        </Button>
      </SectionHead>
      <ContacsTable
        data={contactos}
        status={status}
        columns={columns}
        onRowClicked={onRowClicked}
      />
      <ModalForm
        onSuccesSave={onSuccesSave}
        currentContact={currentContact}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </ContactContainer>
  );
};

const ContactContainer = styled.section`
  padding-bottom: 2rem;
`;

const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 950px;
  max-width: 1200px;
`;
