import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IProduct } from 'models/Products';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { updateProductVideo } from 'features/Catalog/Store/extraReducers/updateProductVideo';
import { useUploadFile, ERutes } from 'hooks/useUploadFile';

interface IProps {
  editing: boolean;
  productData: IProduct;
}

export const Video = ({ editing, productData }: IProps) => {
  const customDispatch = useCustomDispatch();
  const [videoUrl, setVideoUrl] = useState('');
  const { fileStatus, sendFile } = useUploadFile();

  useEffect(() => {
    if (fileStatus.url !== '' && videoUrl !== fileStatus.url) {
      setVideoUrl(fileStatus.url);
      customDispatch({
        asyncThuckFuction: updateProductVideo,
        endPoint: `api/Catalog/Update/${productData.id}`,
        body: {
          id: productData.id,
          name: productData.name,
          imageUrl: productData.imageUrl,
          weight: productData.weight,
          packingType: productData.packingType.id,
          brandId: productData.brand.id,
          productLineId: productData.line.id,
          description: productData.description,
          videoUrl: fileStatus.url,
        },
        method: 'PUT',
      });
      return;
    }
    setVideoUrl(productData?.videoUrl || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileStatus, productData]);

  const updateVideo = (event: any) => {
    const file = event.target.files[0];
    sendFile({
      file,
      name: 'galleryVideo',
      path: ERutes.products,
    });
  };

  const deleteVideo = () => {
    customDispatch({
      asyncThuckFuction: updateProductVideo,
      endPoint: `api/Catalog/Update/${productData.id}`,
      body: {
        id: productData.id,
        name: productData.name,
        imageUrl: productData.imageUrl,
        weight: productData.weight,
        packingType: productData.packingType.id,
        brandId: productData.brand.id,
        productLineId: productData.line.id,
        description: productData.description,
        videoUrl: '',
      },
      method: 'PUT',
    });
  };

  return (
    <Container>
      {videoUrl.length || fileStatus.loading ? (
        <>
          <Label editing={editing}>Video</Label>

          <VideoContainer isLoading={fileStatus.loading}>
            {!fileStatus.loading ? (
              <VideoComponent
                controls={true}
                controlsList='nodownload'
                disablePictureInPicture={true}
                poster={productData.imageUrl}
              >
                <source src={videoUrl} />
              </VideoComponent>
            ) : (
              <ProgressContainer>
                <Text>{`Subiendo ${fileStatus.progress}%`}</Text>
                <Progress progress={fileStatus.progress} />
              </ProgressContainer>
            )}
          </VideoContainer>
        </>
      ) : null}

      {editing && !fileStatus.loading ? (
        <Actions>
          <UploadVideo>
            <>
              <input type='file' accept='video/*' hidden={true} onChange={updateVideo} />
              Subir video
            </>
          </UploadVideo>

          {videoUrl.length ? (
            <DeleteVideo as='button' type='button' onClick={deleteVideo}>
              Eliminar video
            </DeleteVideo>
          ) : null}
        </Actions>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 436px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;

  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--chakra-colors-background-gray);
    `};
`;

const VideoComponent = styled.video`
  width: 100%;
  height: auto;
  min-height: 436px;
  border-radius: 12px;
`;

const Label = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);
  margin-top: 4px;

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      color: #109cf1;
    `}
`;

const Actions = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin: 12px auto;
  column-gap: 12px;
`;

const UploadVideo = styled.label`
  width: 105.66px;
  height: 40px;
  font-size: 1em;
  padding: 8px 11px;
  border-radius: 4px;
  background: var(--chakra-colors-green-primary);
  color: var(--chakra-colors-white-primary);
  font-weight: 700;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const DeleteVideo = styled(UploadVideo)`
  background: var(--chakra-colors-red-negative);
  width: auto;
  height: auto;

  &:hover {
    background: var(--chakra-colors-red-negativeHover);
  }
`;

const ProgressContainer = styled.div`
  width: 400px;
  height: 20px;
  position: relative;
  margin: 12px auto;
`;

const Text = styled.p`
  font-size: 14px;
  color: var(--chakra-colors-black-primary);
  text-align: center;
  z-index: 4;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const Progress = styled.div`
  width: 0%;
  height: 100%;
  background: var(--chakra-colors-yellow-primary);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  ${({ progress }: { progress: number }) =>
    progress &&
    css`
      width: ${progress}%;
    `}
`;
