import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from 'components/common/CustomInput/CustomInput';
import { FormSection } from 'components/common/FormSection/FormSection';
import { Modal } from 'components/common/Modal/Modal';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { SalesPerson } from 'models/SalesPersons';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { salesPersonSchema } from 'schemes/SalesPerson';
import { useAppSelector } from 'store/hooks';
import { updateSalesPerson } from '../../../../features/Settings/SalesPerson/Store/extraReducers/UpdateSalesPerson';
import {
  setShowModal,
  setUpdatedSalesPerson,
} from '../../../../features/Settings/SalesPerson/Store/store';

export const SalesForm = () => {
  const { formModal } = useAppSelector(state => state.settingsSalesPerson);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SalesPerson>({
    resolver: yupResolver(salesPersonSchema),
  });
  const dispatch = useDispatch();
  const customDispath = useCustomDispatch();
  const [formEditing, setFormEditing] = useState<boolean>(true);

  const onSubmit: SubmitHandler<SalesPerson> = data => {
    dispatch(setUpdatedSalesPerson(data));
    customDispath({
      asyncThuckFuction: updateSalesPerson,
      endPoint: `api/SalesPerson/Update/${data.id}`,
      method: 'PUT',
      body: {
        auth0Id: data.auth0Id,
      },
    });
    dispatch(setShowModal(false));
  };

  useEffect(() => {
    reset(formModal.selectedSalesPerson);
  }, [formModal.selectedSalesPerson, reset]);

  const onSave = () => handleSubmit(onSubmit);

  const onEditCancel = () => {
    dispatch(setShowModal(false));
  };

  const commonsProps = {
    errors,
    register,
    editing: formEditing,
  };

  return (
    <Modal
      openModal={formModal.showModal}
      closeModal={() => dispatch(setShowModal(false))}
      noCloseButton={true}
      size={{ width: 502, height: 350 }}
    >
      <FormSection
        title='Editar Vendedor'
        titlePosition='center'
        onSave={onSave()}
        editing={formEditing}
        onEditCancel={onEditCancel}
        setEditing={setFormEditing}
        grid={{
          templateColumns: 'repeat(auto-fit,minmax(300px,370px))',
          templateRows: 'repeat(3,auto)',
          justifyContent: 'center',
          gridGap: '12px',
        }}
      >
        <CustomInput {...commonsProps} isReadOnly={true} name='codigoTams' label='Código' />
        <CustomInput {...commonsProps} isReadOnly={true} name='nombre' label='Nombre' />
        <CustomInput
          {...commonsProps}
          name='auth0Id'
          isRequired={true}
          label='Id de autenticación'
        />
      </FormSection>
    </Modal>
  );
};
