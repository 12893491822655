import {
  Alert,
  AlertDescription,
  AlertIcon,
  Modal,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';

interface IAlert {
  status: StatusApp.Status;
  description: string;
  onModalCLose: () => void;
}

export const CustomAlert = ({ status, description, onModalCLose }: IAlert) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    description.length > 2 && onOpen();
  }, [description.length, onOpen, status]);

  const onCLose = () => {
    onModalCLose && onModalCLose();
    onClose();
  };

  return (
    <>
      <Modal onClose={onCLose} size='sm' isOpen={isOpen}>
        <ModalContent>
          <Alert status={status !== 'succeeded' ? 'error' : 'success'}>
            <AlertIcon />
            <AlertDescription>{description}</AlertDescription>
          </Alert>
        </ModalContent>
      </Modal>
    </>
  );
};
