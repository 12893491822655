import { FC } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

export const Auth0ProviderWithHistory: FC = ({ children }: any) => {
  const domain: string = String(process.env.REACT_APP_AUTH0_DOMAIN);
  const clientId: string = String(process.env.REACT_APP_AUTH0_CLIENT_ID);
  const audience: string = String(process.env.REACT_APP_AUTH0_AUDIENCE);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}`}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};
