import { AgTable } from 'components/common/Table/Table';
import { Columns } from './columns';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { IProduct } from 'models/Products';

export const Table = ({ products }: { products: IProduct[] }) => {
  const history = useHistory();
  return (
    <Container>
      <AgTable
        loading={false}
        rowData={products}
        gridOptions={{ columnDefs: Columns }}
        onRowClicked={event => history.push(`/settings/viewProduct/${event.data.id}`)}
        pagination={true}
      />
    </Container>
  );
};

const Container = styled.div`
  width: calc(100vw - 24px);
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  height: calc(100vh - 220px);
  overflow-y: none;

  & .ag-row {
    cursor: pointer;
  }
`;
