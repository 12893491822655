import styled from 'styled-components';
import ImageViewer from 'react-simple-image-viewer';
import { useCallback, useState } from 'react';

interface IProps {
  imageGallery: string[];
}

export const Gallery = ({ imageGallery }: IProps) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container>
      {imageGallery.map((url, index) => (
        <CardImage src={url} key={index} onClick={() => openImageViewer(index)} />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={imageGallery}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ background: 'rgba(34,34,34, .7)' }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
  column-gap: 12px;
  row-gap: 12px;
`;

const CardImage = styled.img`
  width: 200px;
  border-radius: 4px;
  cursor: pointer;
  height: 100px;
  border: 1px solid #f0f0f0;
`;
