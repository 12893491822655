import { useState, useEffect } from 'react';
import { ERutes, uploadImage } from 'services/uploadFile';
import { MdDelete } from 'react-icons/md';
import styled, { css, keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import {
  addImageToProductGallery,
  deleteImageToProductGallery,
} from 'features/Catalog/Store/store';
import { sendImageToProductGallery } from 'features/Catalog/Store/extraReducers/sendImageToProductGallery';
import { removeImageToProductGallery } from 'features/Catalog/Store/extraReducers/deleteImageToProductGallery';
import { useAppSelector } from 'store/hooks';
import { IMAGE_UPLOAD_PLACEHOLDER } from 'utils/imagesOfUtilities';

interface IProps {
  imageGallery: string[];
  editing: boolean;
  productId: string;
}

export const Gallery = ({ imageGallery, editing, productId }: IProps) => {
  const dispatchAsync = useCustomDispatch();
  const dispatch = useDispatch();
  const [allImages, setAllImages] = useState([{ url: '', isAdded: false }]);
  const { addImageToProductGallery: addImage, deleteImageToProductGallery: removeImage } =
    useAppSelector(state => state.products);
  const [imageThatIsBeingUploaded, setImageThatIsBeingUploaded] = useState({
    loading: false,
    localUrl: '',
    serverUrl: '',
  });

  useEffect(() => {
    if (removeImage.status === 'succeeded') {
      setAllImages(allImages.filter(image => image.url !== removeImage.url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeImage.status]);

  useEffect(() => {
    setAllImages(
      imageGallery.map(url => {
        return { url, isAdded: true };
      })
    );
  }, [imageGallery]);

  useEffect(() => {
    if (addImage.status === 'succeeded' && imageThatIsBeingUploaded.serverUrl !== '') {
      setAllImages(
        allImages.map(image => {
          if (image.url === imageThatIsBeingUploaded.localUrl) {
            return { url: imageThatIsBeingUploaded.serverUrl, isAdded: true };
          }
          return image;
        })
      );
      setImageThatIsBeingUploaded({ localUrl: '', serverUrl: '', loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addImage.status, imageThatIsBeingUploaded]);

  const submitImage = async ({ file, oldUrl }: { file: File; oldUrl: string }) => {
    const imageUrl = await uploadImage({
      file,
      name: 'galleryImage',
      path: ERutes.products,
    });
    setImageThatIsBeingUploaded({ ...imageThatIsBeingUploaded, serverUrl: imageUrl });
    dispatch(addImageToProductGallery({ id: productId, url: imageUrl }));
    dispatchAsync({
      asyncThuckFuction: sendImageToProductGallery,
      endPoint: `api/Catalog/AddImageToProduct/${productId}`,
      body: {
        imageUrl,
      },
      method: 'PUT',
    });
  };

  const deleteImage = (url: string) => {
    dispatch(deleteImageToProductGallery({ id: productId, url }));
    dispatchAsync({
      asyncThuckFuction: removeImageToProductGallery,
      endPoint: `api/Catalog/DeleteImageFromProduct/${productId}`,
      body: {
        imageUrl: url,
      },
      method: 'DELETE',
    });
  };

  const handleChangeImage = (event: any) => {
    const file = event.target.files[0];

    if (file.type.indexOf('image/') !== -1) {
      setAllImages([{ url: URL.createObjectURL(file), isAdded: false }, ...allImages]);
      submitImage({ file, oldUrl: URL.createObjectURL(file) });
      setImageThatIsBeingUploaded({
        localUrl: URL.createObjectURL(file),
        serverUrl: '',
        loading: true,
      });
    }
  };

  return (
    <Container>
      {allImages.length ? <Label editing={editing}>Galería</Label> : null}
      <ImageGallery>
        {editing && !imageThatIsBeingUploaded.loading && (
          <AddImage>
            <input
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            <Img src={IMAGE_UPLOAD_PLACEHOLDER} />
          </AddImage>
        )}

        {allImages.map((image, index) => (
          <CardImage key={index}>
            <Img src={image.url} />
            {image.isAdded && editing && (
              <Delete type='button' onClick={() => deleteImage(image.url)}>
                <DeleteIcon />
              </Delete>
            )}
            {!image.isAdded && editing && (
              <CircularProgress>
                <Loader />
              </CircularProgress>
            )}
          </CardImage>
        ))}
      </ImageGallery>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 12px;
`;

const ImageGallery = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
  column-gap: 12px;
  row-gap: 12px;
`;

const Label = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);
  margin-top: 4px;

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      color: #109cf1;
    `}
`;

const CardImage = styled.div`
  width: 150px;
  border-radius: 4px;
  height: auto;
  border: 1px solid #f0f0f0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Delete = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: var(--chakra-colors-red-negative);
  cursor: pointer;

  &:hover {
    background: var(--chakra-colors-red-negativeHover);
  }
`;

const DeleteIcon = styled(MdDelete)`
  color: var(--chakra-colors-red-degraded);
  font-size: 20px;
`;

const AddImage = styled.label`
  display: block;
  width: 150px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center center;
`;

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const CircularProgress = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  background: rgb(194, 207, 224);
`;

export const Loader = styled.div`
  border: 4px solid #ffff;
  border-radius: 50%;
  border-top: 4px solid rgb(51, 77, 110);
  width: 20px;
  height: 20px;
  animation: ${loadingAnimation} 2s linear infinite;
`;
