import CatalogFinderAndFilter from 'components/Catalog/CatalogFinderAndFilter/CatalogFinderAndFilter';
import Brands from 'components/Catalog/Brands/Brands';
import Catalog from 'components/Catalog/Catalog/Catalog';
import { useState } from 'react';
import { useGetCatalogData } from 'hooks/useGetCatalogData';

interface ITypeFilter {
  line: number | string;
  brand: number | string;
}

interface IHandleFilter {
  type: string;
  id: number | string;
}

const ProductCatalog = () => {
  useGetCatalogData();
  const [filters, setFilters] = useState<ITypeFilter>({ line: 'noFilter', brand: 'noFilter' });
  const [toSearch, setToSearch] = useState('');

  const handleFilters = ({ id, type }: IHandleFilter) => {
    // Filter products by line or brand
    type === 'brand' && setFilters({ line: filters.line, brand: id });
    type === 'line' && setFilters({ line: id, brand: filters.brand });
  };

  return (
    <>
      <CatalogFinderAndFilter
        setFilters={handleFilters}
        searchProduct={value => setToSearch(value)}
      />
      <Brands setFilters={handleFilters} />
      <Catalog filterByBrand={filters.brand} filterByLine={filters.line} toSearch={toSearch} />
    </>
  );
};

export default ProductCatalog;
