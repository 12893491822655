import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendNewLine = createAsyncThunk('lines/addNewLine', httpService);

export const addNewLineReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendNewLine.fulfilled, (state, action) => {
    state.lines.unshift({ ...state.newLine.data, id: action.payload });
    state.newLine.status = 'succeeded';
    state.newLine.message = 'Línea creada';
  });

  builder.addCase(sendNewLine.pending, state => {
    state.newLine.status = 'pending';
  });

  builder.addCase(sendNewLine.rejected, (state, action) => {
    state.newLine.status = 'failed';
    state.newLine.message = 'Error al crear línea';
  });
};
