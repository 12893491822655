import { ActionReducerMapBuilder, createAsyncThunk, current } from '@reduxjs/toolkit';
import { IProductBackend } from 'models/Products';
import httpService, { IUseFetch } from '../../../../services/httpService';
import { IState } from '../common';

export const updateProductVideo = createAsyncThunk(
  'catalog/updateProductVideo',
  async (params: IUseFetch) => {
    const { videoUrl, id } = params.body as IProductBackend;
    httpService(params);
    return { videoUrl, id };
  }
);

export const updateProductVideoReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(updateProductVideo.fulfilled, (state, action) => {
    const allProducts = current(state.products);
    const index = allProducts.findIndex(product => product.id === action.payload.id);
    state.products[index].videoUrl = action.payload.videoUrl;
  });
};
