import styled, { css } from 'styled-components';

interface IProps {
  config: {
    register: any;
    errors: any;
    disabled?: boolean;
  };
  name: string;
  label: string;
  defaultValue?: string;
  isRequired?: boolean;
}

export const CustomInput2 = ({ config, label, name, defaultValue, isRequired }: IProps) => {
  return (
    <FormGroup>
      <Label editing={!config.disabled}>{label}</Label>
      <Input
        {...config.register(name, { required: { value: isRequired, message: 'Campo requerido' } })}
        disabled={config.disabled}
        defaultValue={defaultValue}
      />
      {config.errors[name] && <ErrorMessage>{config.errors[name]?.message}</ErrorMessage>}
    </FormGroup>
  );
};

const FormGroup = styled.label`
  width: 100%;
  display: block;
  width: 100%;
  margin: 4px 0;
  position: relative;
  border-radius: 8px;
  border: 2px solid transparent;
`;

type ILabel = {
  editing?: boolean;
  isRequired?: boolean;
};

const Label = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);

  ${({ editing }: ILabel) =>
    editing &&
    css`
      color: #109cf1;

      ${({ isRequired }: ILabel) =>
        !isRequired &&
        css`
          &:after {
            content: ' *';
            color: rgba(230, 55, 70, 1);
          }
        `}
    `}
`;

const ErrorMessage = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(230, 55, 70, 1);
  border-radius: 4px;
`;

const Input = styled.input`
  width: 100%;
  font-size: 1em;
  font-weight: 400;
  padding: 8px 4px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: var(--chakra-colors-background-gray);
  outline: none;

  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    css`
      border: 1px solid #f0f0f0;
      background: var(--chakra-colors-white-primary);
    `};
`;
