import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IPosition } from 'components/common/Map/Map';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { ClienteProfileState } from '../store';

export const getGeolocation = createAsyncThunk('get/Geolocation', async (address: string) => {
  const res = await getGeocode({ address });
  const position = await getLatLng(res[0]);
  return position;
});

export const getGeolocationReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(getGeolocation.fulfilled, (state, action: PayloadAction<IPosition>) => {
    state.stores.map.modifiedLocalization = action.payload;
    state.stores.map.viewMap = action.payload;
  });
};
