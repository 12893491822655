import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { ClienteProfileState } from '../store';

export const updateClientContact = createAsyncThunk('update/clientContact', httpService);

export const updateContactReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(updateClientContact.fulfilled, (state, action) => {
    if (!action.payload) {
      state.message = 'No se pudo actualizar los datos, revisa tu conexión';
      state.status = 'failed';
      return;
    }
    const index = state.clientDetails.contactos.findIndex(
      contact => contact.id === state.modifyContact.id
    );
    state.clientDetails.contactos[index] = state.modifyContact;

    state.message = 'Se ha actualizado con éxito';
    state.status = 'succeeded';
  });

  builder.addCase(updateClientContact.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(updateClientContact.rejected, (state, action) => {
    state.message = 'No se pudo actualizar los datos, revisa tu conexión';
    state.status = 'failed';
  });
};
