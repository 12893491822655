import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 24px;
  @media (min-width: 1200px) {
    margin: 0 auto;
    max-width: 1200px;
  }
  @media (max-width: 500px) {
    margin: 10px;
  }
`;
