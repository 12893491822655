import { ColDef } from 'ag-grid-community/main';

export const Columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Línea',
    sortable: true,
    minWidth: 600,
    flex: 1,
  },
];
