import { object, string, number } from 'yup';

const requiredMessage = 'Campo requerido';

export const ProductSchema = object()
  .shape({
    name: string().required(requiredMessage).defined(),
    weight: string().required(requiredMessage).defined(),
    description: string().required(requiredMessage).defined(),
    line: object()
      .shape({
        id: string(),
        name: string(),
      })
      .required(requiredMessage)
      .nullable(),
    brand: object()
      .shape({
        id: string(),
        name: string(),
      })
      .required(requiredMessage)
      .nullable(),
    packingType: object()
      .shape({
        id: number(),
        name: string(),
      })
      .required(requiredMessage)
      .nullable(),
  })
  .defined();
