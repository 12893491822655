import { Button } from '@chakra-ui/button';
import { RowClickedEvent } from 'ag-grid-community';
import { AgTable } from 'components/common/Table/Table';
import { TableContainer } from 'components/common/TableContainer/TableContainer';
import { TitleIcon } from 'components/common/TitleIcon/TitleIcon';
import { FaStoreAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { setStoreStatus } from '../../../features/ClientProfile/store/store';
import { columns } from './columns';

export const ClientStores = () => {
  const {
    clientDetails: { sucursales },
    status,
  } = useAppSelector(state => state.clientDetails);
  const dispatch = useDispatch();
  const history = useHistory();

  const onRowClicked = (event: RowClickedEvent) => {
    history.push(`${history.location.pathname}/tiendas/${event.data.codigo}`);
    dispatch(setStoreStatus('updating'));
  };

  const onCreateStore = () => {
    history.push(`${history.location.pathname}/tiendas/create`);
    dispatch(setStoreStatus('creating'));
  };

  return (
    <StoresContainer>
      <SectionHead>
        <TitleIcon text='Tiendas' icon={FaStoreAlt} />
        <Button
          color='white'
          onClick={onCreateStore}
          bgColor='green.300'
          _hover={{ backgroundColor: 'var(--chakra-colors-green-primaryHover)' }}
          h={6}
          _active={{ backgroundColor: 'var(--chakra-colors-green-primaryHover)' }}
          _focus={{ border: 'none' }}
        >
          Crear
        </Button>
      </SectionHead>
      <TableContainer>
        <AgTable
          rowData={sucursales}
          loading={status === 'pending' ? true : false}
          gridOptions={{ columnDefs: columns }}
          onRowClicked={onRowClicked}
          pagination={true}
        />
      </TableContainer>
    </StoresContainer>
  );
};

const StoresContainer = styled.section`
  padding-bottom: 2rem;
`;

const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 950px;
  max-width: 1200px;
`;
