import ImageViewer from 'react-simple-image-viewer';
import { useCallback, useState } from 'react';
import { ImageCard } from '../ImageCard/ImageCard';
import { IImageSucursal } from 'models/ClientDetails';

interface IProps {
  imageGallery: IImageSucursal[];
  onDelete: (idImage: string, firebaseImageUrl: string) => void;
}

export const Carousel = ({ imageGallery, onDelete }: IProps) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      {imageGallery.map(({ id, imageUrl }, index) => (
        <ImageCard
          key={index}
          src={imageUrl}
          onDelete={() => onDelete(id, imageUrl)}
          onClick={() => openImageViewer(index)}
        />
      ))}

      {isViewerOpen && (
        <div style={{ margin: 0 }}>
          <ImageViewer
            src={imageGallery.map(img => img.imageUrl)}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{ background: 'rgba(34,34,34, .7)' }}
          />
        </div>
      )}
    </>
  );
};
