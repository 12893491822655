import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IMAGE_PLACEHOLDER } from 'utils/imagesOfUtilities';
import { IProduct } from 'models/Products';

export const ProductCard = ({ product }: { product: IProduct }) => {
  const history = useHistory();
  const { line, imageUrl, name, brand, id } = product;

  return (
    <Container onClick={() => history.push(`/catalog/viewProduct/${id}`)}>
      <ProductImage>
        <Image src={imageUrl || IMAGE_PLACEHOLDER} alt={name} />
      </ProductImage>

      <ProductText>
        <Name>{name}</Name>
        <Label>
          <Bold>Marca: </Bold>
          {brand.name}
        </Label>
        <Label>
          <Bold>Linea: </Bold>
          {line.name}
        </Label>
      </ProductText>
    </Container>
  );
};

const Container = styled.section`
  width: 250px;
  height: 261px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 6px rgba(39, 31, 32, 0.3);
  background: #fff;
  cursor: pointer;
`;

const ProductImage = styled.div`
  width: 100%;
  height: 162px;
  overflow: hidden;
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
`;

const ProductText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 8px 8px;
`;

const Name = styled.h3`
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 0.88em;
  font-weight: 400;
`;

const Bold = styled.span`
  font-size: 1em;
  font-weight: 700;
  color: var(--chakra-colors-black-primary);
`;
