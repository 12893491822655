import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { CustomAlert } from 'components/common/Alert/Alert';
import { Table } from 'components/settings/catalog/Products/Table/Table';
import { IProduct } from 'models/Products';
import { useGetCatalogData } from 'hooks/useGetCatalogData';
import { useDispatch } from 'react-redux';
import { resetProductsStatus } from 'features/Catalog/Store/store';

const Products = () => {
  useGetCatalogData();
  const dispatch = useDispatch();
  const {
    products: allProducts,
    createProduct,
    productToDelete,
    updateProduct,
  } = useAppSelector(state => state.products);
  const [products, setProducts] = useState(allProducts);
  const history = useHistory();

  const handleSearch = (toSearch: string) => {
    if (toSearch.length) {
      const productsResult: IProduct[] = [];

      allProducts.forEach(product => {
        if (product.name.toLowerCase().includes(toSearch.toLowerCase())) {
          productsResult.push(product);
        }
      });

      setProducts(productsResult);
      return;
    }
    setProducts(allProducts);
  };

  useEffect(() => {
    if (allProducts.length) {
      setProducts(allProducts);
      return;
    }
    setProducts([]);
  }, [allProducts]);

  return (
    <Container>
      <CustomAlert
        description={createProduct.message}
        status={createProduct.status}
        onModalCLose={() => dispatch(resetProductsStatus())}
      />
      <CustomAlert
        description={productToDelete.message}
        status={productToDelete.status}
        onModalCLose={() => dispatch(resetProductsStatus())}
      />
      <CustomAlert
        description={updateProduct.message}
        status={updateProduct.status}
        onModalCLose={() => dispatch(resetProductsStatus())}
      />

      <ArrowBack onClick={() => history.push('/settings')} />
      <Search placeholder='Buscar producto' onChange={({ target }) => handleSearch(target.value)} />

      <AddContainer>
        <AddProduct onClick={() => history.push('/createProduct')}>Agregar producto</AddProduct>
      </AddContainer>

      <Table products={products} />
    </Container>
  );
};

export default Products;

const Container = styled.div`
  width: calc(100% - 24px);
  max-width: 1200px;
  margin: 12px auto 0 auto;
  height: 100%;
  background: var(--chakra-colors-white-primary);
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
`;

const Search = styled.input`
  margin: 12px auto 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 8px;
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  background: #f5f8fa;
  border-radius: 8px;
  outline: none;

  &::placeholder {
    font-size: 1em;
    color: var(--chakra-colors-gray-primary);
  }
`;

const AddProduct = styled.button`
  padding: 2px 4px;
  font-size: 0.8em;
  color: var(--chakra-colors-green-degraded);
  background: var(--chakra-colors-green-primary);
  font-weight: 700;
  border-radius: 4px;
  transition: 0.3s ease-in-out;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
  &:focus {
    outline: none;
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const AddContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin: 24px auto 12px auto;
`;

const ArrowBack = styled(BiArrowBack)`
  font-size: 24px;
  color: var(--chakra-colors-black-primary);
  margin: 8px auto 0 8px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: translateX(-3px);
  }
`;
