import { useState } from 'react';
import { FormSection } from 'components/common/FormSection/FormSection';
import { Wrap } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { ERutes } from 'services/uploadFile';
import { useAppSelector } from 'store/hooks';
import { setMessageEmpty } from 'features/ClientProfile/store/store';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { saveImage } from 'features/ClientProfile/store/extraReducers/SaveImageInDb';
import { CameraModal } from './ModalCamera/ModalCamera';
import { deleteImage } from 'features/ClientProfile/store/extraReducers/DeleteImage';
import { ImageCard } from './ImageCard/ImageCard';
import { CustomAlert } from 'components/common/Alert/Alert';
import { Carousel } from './Carousel/Carousel';

export const StoresGalery = () => {
  const {
    stores: { currentStore },
    clientDetails: {
      cliente: { codigoTams },
    },
    modifyContact: { id },
    message,
    status,
  } = useAppSelector(state => state.clientDetails);

  const [showCamera, setShowCamera] = useState(false);

  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();

  const onSave = (base64: string) => {
    customDispatch({
      asyncThuckFuction: saveImage,
      endPoint: 'api/Clientes/AddImageToSucursal',
      body: {
        customerId: codigoTams,
        storeId: currentStore.codigo,
        firebase: {
          name: `${codigoTams}/${id || 0}`,
          base64,
          path: ERutes.stores,
        },
      },
    });
    setShowCamera(false);
  };

  const onDeleteImage = (idImage: string, firebaseImageUrl: string) => {
    customDispatch({
      asyncThuckFuction: deleteImage,
      endPoint: `api/Clientes/RemoveImageFromSucursal/${idImage}`,
      method: 'DELETE',
      body: {
        firebaseImageUrl,
        idImage,
      },
    });
  };

  const onModalCLose = () => {
    dispatch(setMessageEmpty());
  };

  return (
    <>
      <CustomAlert onModalCLose={onModalCLose} description={message} status={status} />
      <FormSection title='Galería' titlePosition='start'>
        <Wrap>
          <ImageCard onClick={() => setShowCamera(true)} />
          <Carousel onDelete={onDeleteImage} imageGallery={currentStore.images} />
        </Wrap>
      </FormSection>
      <CameraModal isOpen={showCamera} onClose={() => setShowCamera(false)} onSave={onSave} />
    </>
  );
};
