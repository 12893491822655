import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FC } from 'react';

interface ITableContainer {
  calcHeigth?: string;
  maxWidth?: number;
}

export const TableContainer: FC<ITableContainer> = ({
  children,
  calcHeigth = '240px',
  maxWidth = 1050,
}) => {
  return (
    <Container>
      <TableCont h={`calc(100vh - ${calcHeigth})`} maxW={maxWidth}>
        {children}
      </TableCont>
    </Container>
  );
};

const TableCont = styled(Box)`
  background-color: var(--chakra-colors-white-primary);
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
  margin-top: 12px;
  padding: 0 1rem;
  padding-top: 1rem;
`;

const Container = styled(Box)`
  background-color: var(--chakra-colors-white-primary);
  border-radius: 12px 12px 0 0;
  max-width: 1200px;
  height: 100%;
`;
