import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { MdDashboard, MdFace, MdFolderOpen, MdSettings } from 'react-icons/md';
import { LinkButton } from './LinkButton/LinkButton';
import { GrLogout } from 'react-icons/gr';
import { Container } from '../../Container/Container';

export const Navbar = () => {
  return (
    <Nav align='center'>
      <Container>
        <Links>
          <LinkButton to='/' leftIcon={<MdDashboard size='1.3em' />}>
            Dashboard
          </LinkButton>
          <LinkButton to='/clientes' leftIcon={<MdFace size='1.3em' />}>
            Clientes
          </LinkButton>
          <LinkButton to='/catalog' leftIcon={<MdFolderOpen size='1.3em' />}>
            Catálogo
          </LinkButton>
          <LinkButton to='/settings' leftIcon={<MdSettings size='1.3em' />}>
            Configuración
          </LinkButton>
          <LinkButton to='/logout' leftIcon={<GrLogout size='1.3em' />}>
            Cerrar sesión
          </LinkButton>
        </Links>
      </Container>
    </Nav>
  );
};

const Nav = styled(Flex)`
  background: var(--chakra-colors-gray-icon) !important;
  padding: 12px 0px;
  width: 100%;
  height: 64px;
  overflow-x: auto;
`;

const Links = styled(Flex)`
  @media (min-width: 1200px) {
    width: 1200px;
    margin: 0 auto;
  }
`;
