import styled, { css } from 'styled-components';

interface Iprops {
  active?: boolean;
  image: string;
  name: string;
  id: string;
  brandSelected?: any;
  disabled?: boolean;
}

export const BrandCard = ({ active, image, name, id, brandSelected, disabled }: Iprops) => {
  return (
    <Container active={active || false} onClick={() => brandSelected(id)} disabled={disabled}>
      <ImageContainer>
        <Image src={image} alt={name} />
      </ImageContainer>

      <Title>{name.length > 30 ? `${name.slice(0, 28)}...` : name}</Title>
    </Container>
  );
};

type IContainer = {
  active?: boolean;
  disabled?: boolean;
};

const Container = styled.article`
  width: 130px;
  height: 162px;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
  background: var(--chakra-colors-white-primary);
  display: grid;
  grid-template-rows: 93px auto;
  padding: 8px 2px 0px 2px;
  cursor: pointer;

  &:hover {
    border: 2px solid var(--chakra-colors-yellow-primary);
  }

  ${({ active }: IContainer) =>
    active &&
    css`
      position: relative;
      border: 2px solid var(--chakra-colors-yellow-primary);
      border-radius: 12px;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        background: rgba(244, 236, 34, 0.1);
      }
    `};

  ${({ disabled }: IContainer) =>
    disabled &&
    css`
      cursor: default;
      &:hover {
        border: 2px solid #f0f0f0;
      }
    `}
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 93px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 4px;
`;

const Title = styled.h4`
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  margin: auto;
`;
