import styled, { css } from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { useEffect, useState } from 'react';
import { IProduct } from 'models/Products';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomInput2 } from 'components/common/CustomInput2/CustomInput2';
import { CustomSelect } from 'components/common/CustomSelect/CustomSelect';
import { ProductSchema } from 'schemes/Product';
import { useDispatch } from 'react-redux';
import { deleteProduct, updateProduct } from 'features/Catalog/Store/store';
import { sendProductUpdated } from 'features/Catalog/Store/extraReducers/updateProduct';
import { sendDeleteProduct } from 'features/Catalog/Store/extraReducers/deleteProduct';
import { FaFileUpload } from 'react-icons/fa';
import { useGetCatalogData } from 'hooks/useGetCatalogData';
import { ERutes, uploadImage } from 'services/uploadFile';
import { Actions } from './Actions';
import { Gallery } from './Gallery';
import { Video } from './Video';
import { IMAGE_PLACEHOLDER } from 'utils/imagesOfUtilities';

const packingTypeOptions = [
  { id: 0, name: 'Termoformado' },
  { id: 1, name: 'Bolsa' },
  { id: 2, name: 'Bolsa al vacío' },
];

const ViewProduct = () => {
  useGetCatalogData();
  const history = useHistory();
  const dispatchAsync = useCustomDispatch();
  const dispatch = useDispatch();
  const { id }: { id: any } = useParams();
  const { products, brands, lines } = useAppSelector(state => state.products);
  const [brandsOptions, setBrandsOptions] = useState([{ id: '', name: '' }]);
  const [productToView, setProductToView] = useState<IProduct>({} as IProduct);
  const [image, setImage] = useState({ file: File, url: IMAGE_PLACEHOLDER, updateImage: false });
  const [editing, setEditing] = useState(false);
  const [clear, setClear] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<IProduct>({
    resolver: yupResolver(ProductSchema),
  });

  // set brands options
  useEffect(() => {
    if (brands.length) {
      const allBrands = brands.map(brand => {
        return { id: brand.id, name: brand.name };
      });
      setBrandsOptions(allBrands);
    }
  }, [brands]);

  // get product by id
  useEffect(() => {
    if (products.length) {
      const index = products.findIndex(product => product.id === id);
      setProductToView(products[index]);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, products]);

  // reset
  useEffect(() => {
    if (Object.keys(productToView).length) {
      reset(productToView);
    }
    setImage({ ...image, url: productToView.imageUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productToView]);

  const inputsConfig = {
    register,
    errors,
    disabled: !editing,
    getValues,
    setValue,
    clear,
    setError,
  };

  const onSubmit = async (data: IProduct) => {
    const imageUrl = await uploadImage({
      file: image.file,
      name: data.name,
      path: ERutes.products,
    });

    dispatch(
      updateProduct({
        ...data,
        imageUrl: image.updateImage ? `${imageUrl}` : productToView.imageUrl,
      })
    );
    dispatchAsync({
      asyncThuckFuction: sendProductUpdated,
      endPoint: `api/Catalog/Update/${data.id}`,
      body: {
        name: data.name,
        imageUrl: image.updateImage ? `${imageUrl}` : productToView.imageUrl,
        weight: data.weight,
        packingType: data.packingType.id,
        brandId: data.brand.id,
        productLineId: data.line.id,
        description: data.description,
        videoUrl: productToView.videoUrl,
        imageGallery: productToView.imageGallery,
      },
      method: 'PUT',
    });

    setEditing(false);
    history.push('/settings/products');
  };

  const handleDelete = () => {
    dispatch(deleteProduct(id));
    dispatchAsync({
      asyncThuckFuction: sendDeleteProduct,
      endPoint: `api/Catalog/Delete/${id}`,
      method: 'DELETE',
    });

    setEditing(false);
    history.push('/settings/products');
  };

  const handleImage = (event: any) => {
    const file = event.target.files[0];

    if (file.type.indexOf('image/') !== -1) {
      setImage({ file, url: URL.createObjectURL(file), updateImage: true });
    }
  };

  return (
    <Container>
      <ArrowBack onClick={() => history.goBack()} />

      <ImageContainer editing={editing}>
        <Image src={image.url} />
        {editing && (
          <input type='file' accept='image/*' style={{ display: 'none' }} onChange={handleImage} />
        )}
        {errors.imageUrl?.message && <ErrorMessage>{errors.imageUrl?.message}</ErrorMessage>}
        {editing && (
          <EditImage>
            <EditIconImage />
          </EditImage>
        )}
      </ImageContainer>

      <Form
        onSubmit={ev => {
          ev.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <Title>Información</Title>
        <CustomInput2
          config={inputsConfig}
          name='name'
          label='Nombre'
          defaultValue={productToView.name}
          isRequired={true}
        />
        <CustomSelect
          config={inputsConfig}
          name='brand'
          options={brandsOptions || brandsOptions}
          label='Marca'
          isRequired={true}
        />
        <CustomSelect
          config={inputsConfig}
          name='line'
          options={lines || brandsOptions}
          label='Linea'
          isRequired={true}
        />
        <CustomSelect
          config={inputsConfig}
          name='packingType'
          options={packingTypeOptions}
          label='Empaque'
          isRequired={true}
        />
        <CustomInput2
          config={inputsConfig}
          name='weight'
          label='Peso'
          defaultValue={productToView.weight}
          isRequired={true}
        />
        <CustomInput2
          config={inputsConfig}
          name='description'
          label='Nota del producto'
          defaultValue={productToView.description}
          isRequired={true}
        />
        <Gallery
          imageGallery={productToView.imageGallery || []}
          editing={editing}
          productId={productToView.id}
        />
        <Video editing={editing} productData={productToView} />
        <Actions
          editing={editing}
          onDelete={() => handleDelete()}
          onCancel={() => {
            setClear(true);
            setEditing(false);
            reset(productToView);
          }}
          onEdit={() => setEditing(true)}
        />
      </Form>
    </Container>
  );
};

export default ViewProduct;

const Container = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 0 auto 40px auto;
`;

const ArrowBack = styled(BiArrowBack)`
  font-size: 24px;
  color: var(--chakra-colors-black-primary);
  margin: 20px auto 0 8px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: translateX(-3px);
  }
`;

const ImageContainer = styled.label`
  width: 300px;
  margin: 12px auto 0 auto;
  display: flex;
  justify-content: center;
  cursor: default;
  position: relative;
  background: #fff;
  border-radius: 12px;

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      cursor: pointer;
    `}
`;

const Image = styled.img`
  width: 300px;
  height: 200px;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
`;

const Form = styled.form`
  width: 95%;
  max-width: 800px;
  margin: 36px auto 0 auto;
  position: relative;
  background: var(--chakra-colors-white-primary);
  border-radius: 12px;
  padding: 0 12px 12px 12px;
`;

const Title = styled.p`
  text-align: center;
  font-size: 1em;
  padding-top: 24px;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);
`;

const EditImage = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;
  background: var(--chakra-colors-yellow-primary);
  position: absolute;
  right: -10px;
  bottom: -10px;
  z-index: 9;

  &:hover {
    background: var(--chakra-colors-yellow-primaryHover);
  }
`;

const EditIconImage = styled(FaFileUpload)`
  color: var(--chakra-colors-black-primary);
  font-size: 17px;
`;

const ErrorMessage = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(230, 55, 70, 1);
  border-radius: 4px;
  position: absolute;
  bottom: -18px;
  left: 8px;
`;
