import { ColDef } from 'ag-grid-community/main';

export const columns: ColDef[] = [
  {
    flex: 1,
    field: 'codigo',
    headerName: 'Código',
    sortable: true,
    sortIndex: 1,
    maxWidth: 100,
  },
  {
    flex: 1,
    field: 'nombre',
    headerName: 'Nombre',
    wrapText: true,
    resizable: true,
    sortIndex: 2,
    autoHeight: true,
    minWidth: 250,
    initialWidth: 100,
  },
  {
    flex: 1,
    field: 'direccion',
    headerName: 'Dirección',
    minWidth: 250,
    wrapText: true,
    autoHeight: true,
  },
  {
    flex: 1,
    field: 'contacto',
    headerName: 'Contacto',
    wrapText: true,
    resizable: true,
    maxWidth: 200,
    sortIndex: 4,
    minWidth: 150,
  },
];
