import { Icon, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const TitleIcon = ({ text, icon }: { text: string; icon: any }) => {
  return (
    <IconText>
      <Icon w={6} h={6} as={icon} />
      <StiledTitle>{text}</StiledTitle>
    </IconText>
  );
};

const StiledTitle = styled(Text)`
  font-weight: bold;
  font-size: larger;
  width: fit-content;
  margin-left: 0.3rem;
`;

const IconText = styled.div`
  display: flex;
  color: var(--chakra-colors-gray-table);
  justify-content: space-space-around;
`;
