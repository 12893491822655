import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IBrands } from '../../../../models/Products';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const getBrands = createAsyncThunk('brands/getBrands', httpService);

export const getBrandsReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(getBrands.fulfilled, (state, action: PayloadAction<IBrands[]>) => {
    state.brands = action.payload;
    state.brandsStatus = 'succeeded';
  });

  builder.addCase(getBrands.pending, state => {
    state.brandsStatus = 'pending';
  });

  builder.addCase(getBrands.rejected, state => {
    state.brandsStatus = 'failed';
  });
};
