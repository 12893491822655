import { Text, useMediaQuery } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface ICustomLink extends NavLinkProps {
  to: string;
  leftIcon: ReactNode;
}

export const LinkButton: FC<ICustomLink> = ({ leftIcon, children, to, ...rest }) => {
  const [isMinorThan500] = useMediaQuery('(min-width: 500px)');
  return (
    <StyledLink
      activeStyle={{ backgroundColor: 'var(--chakra-colors-yellow-primary)' }}
      to={to}
      isActive={e => Boolean(e?.isExact)}
      {...rest}
    >
      <Icon>{leftIcon}</Icon>
      {isMinorThan500 && (
        <Text flexWrap='nowrap' w='fit-content' ml={2}>
          {' '}
          {children}
        </Text>
      )}
    </StyledLink>
  );
};

const Icon = styled.span`
  justify-self: flex-start;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: var(--chakra-colors-white-primary);
  margin-right: 12px;
  border-radius: 8px;
  min-width: 50px;
  width: auto;
  font-size: 1em;
  padding: 8px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
