import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Tab } from 'components/common/Tab/Tab';
import { FaStoreAlt, FaUserCog } from 'react-icons/fa';
import { MdContactPhone } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { EMainNav, setMainNav } from '../store/store';

export const ClientTabs = () => {
  const dispatch = useDispatch();
  const selected = useAppSelector(state => state.clientDetails.mainNav);

  const setSection = (to: EMainNav) => void dispatch(setMainNav(to));

  return (
    <CardContainer>
      <Tab
        text='Información'
        selected={selected}
        to={EMainNav.informacion}
        setSection={setSection}
        icon={FaUserCog}
      />
      <Tab
        text='Contactos'
        selected={selected}
        to={EMainNav.contacto}
        setSection={setSection}
        icon={MdContactPhone}
      />
      <Tab
        text='Tiendas'
        selected={selected}
        to={EMainNav.tiendas}
        setSection={setSection}
        icon={FaStoreAlt}
      />
    </CardContainer>
  );
};

const CardContainer = styled(Flex)`
  height: 100px;
  width: 100%;
  margin-top: 12px;
`;
