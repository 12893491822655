import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendDeleteProduct = createAsyncThunk('catalog/deleteProduct', httpService);

export const deleteProductReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendDeleteProduct.fulfilled, state => {
    state.products = state.products.filter(product => product.id !== state.productToDelete.id);
    state.productToDelete.status = 'succeeded';
    state.productToDelete.message = 'Producto eliminado';
  });

  builder.addCase(sendDeleteProduct.pending, state => {
    state.productToDelete.status = 'pending';
  });

  builder.addCase(sendDeleteProduct.rejected, state => {
    state.productToDelete.status = 'failed';
    state.productToDelete.message = 'Error al eliminar producto';
  });
};
