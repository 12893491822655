import { RowClickedEvent } from 'ag-grid-community';
import { CustomAlert } from 'components/common/Alert/Alert';
import { AgTable } from 'components/common/Table/Table';
import { TableContainer } from 'components/common/TableContainer/TableContainer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  setCurrentSalesPerson,
  setMessageEmpty,
} from '../../../../features/Settings/SalesPerson/Store/store';

import { columns } from './columns';

export const SalesTable = () => {
  const { salesPersons, status, message } = useAppSelector(state => state.settingsSalesPerson);
  const dispath = useDispatch();
  const onRowClicked = (event: RowClickedEvent) => {
    dispath(setCurrentSalesPerson({ codigoTams: event.data.codigoTams }));
  };

  const onModalCLose = () => {
    dispath(setMessageEmpty());
  };

  return (
    <>
      <CustomAlert onModalCLose={onModalCLose} description={message} status={status} />
      <TableContainer calcHeigth='190px' maxWidth={800}>
        <AgTable
          rowData={salesPersons}
          pagination={true}
          gridOptions={{
            columnDefs: columns,
            defaultColDef: {
              resizable: true,
            },
          }}
          onRowClicked={onRowClicked}
          loading={!salesPersons && status === 'pending'}
        />
      </TableContainer>
    </>
  );
};
