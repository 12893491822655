import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { clientesReducer } from '../features/Clientes/store/store';
import { clienteProfileReducer } from '../features/ClientProfile/store/store';
import productsSlice from '../features/Catalog/Store/store';
import { salesPersonReducer } from '../features/Settings/SalesPerson/Store/store';
export const store = configureStore({
  reducer: {
    clientes: clientesReducer,
    clientDetails: clienteProfileReducer,
    products: productsSlice,
    settingsSalesPerson: salesPersonReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
