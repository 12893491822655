import { Box, BoxProps, Icon, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface ICard extends BoxProps {
  icon: any;
  text: string;
  selected: number | string;
  to: string | number;
  setSection: (value: any) => void;
}

export const Tab = ({ icon, text, setSection, to, selected }: ICard) => {
  return (
    <StyledCard selected={selected === to} onClick={() => setSection(to)}>
      <Icon w={10} h={10} as={icon} />
      <Text fontWeight='bold' children={text} />
    </StyledCard>
  );
};

const bg = 'var(--chakra-colors-white-primary)';
const bgSelected = 'rgba(244, 236, 34, 0.1)';

const StyledCard = styled(Box)`
  height: 75px;
  width: 120px;
  background-color: ${({ selected }: { selected?: boolean }) => (selected ? bgSelected : bg)};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid var(--chakra-colors-yellow-primary);
  margin-right: 10px;
  cursor: pointer;
  min-width: 90px;
`;
