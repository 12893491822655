import styled, { css } from 'styled-components';
import { FaFileUpload, FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { IoCloseSharp } from 'react-icons/io5';
import { sendNewBrand } from 'features/Catalog/Store/extraReducers/createBrand';
import { updateBrand } from 'features/Catalog/Store/extraReducers/updateBrand';
import { createBrand, deleteBrand, setUpdateBrand } from 'features/Catalog/Store/store';
import { useEffect, useState } from 'react';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { Modal } from 'components/common/Modal/Modal';
import { CustomInput2 } from 'components/common/CustomInput2/CustomInput2';
import { MdDelete } from 'react-icons/md';
import { sendDeleteBrand } from 'features/Catalog/Store/extraReducers/deleteBrand';
import { ERutes, uploadImage } from 'services/uploadFile';
import { IBrand, IBrandForm } from './common';
import { IMAGE_PLACEHOLDER } from 'utils/imagesOfUtilities';

interface IProps {
  openModal: boolean;
  closeModal: any;
  brandStatus: { brand: IBrand; addBrand: boolean; viewBrand: boolean };
}

export const CreateAndEditBrand = ({ openModal, closeModal, brandStatus }: IProps) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState({
    file: File,
    url: '',
    updateImage: false,
  });
  const dispatchAsync = useCustomDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm<IBrandForm>();

  const inputsConfig = {
    register,
    errors,
    getValues,
    setValue,
    setError,
  };

  useEffect(() => {
    if (brandStatus.brand?.id) {
      reset({ name: brandStatus.brand.name });
    }
    setImage({
      file: File,
      url: brandStatus.viewBrand ? brandStatus.brand.image : IMAGE_PLACEHOLDER,
      updateImage: false,
    });
  }, [brandStatus, reset]);

  const handleCloseModal = () => {
    closeModal({ brand: {} as IBrand, addBrand: false, viewBrand: false });
    reset({ name: '' });
  };

  const createNewBrand = async (data: IBrandForm) => {
    if (image.file.name === 'File') {
      setError('image', { message: 'Imagen requerida' });
      return;
    }

    setError('image', { message: '' });
    const imageUrl = await uploadImage({
      file: image.file,
      name: data.name,
      path: ERutes.brands,
    });

    dispatch(createBrand({ name: data.name, image: imageUrl }));
    dispatchAsync({
      asyncThuckFuction: sendNewBrand,
      endPoint: 'api/ProductBrand/Create',
      body: {
        name: data.name,
        image: imageUrl,
      },
      method: 'POST',
    });
    handleCloseModal();
  };

  const updateOneBrand = async (data: IBrandForm) => {
    const imageUrl = await uploadImage({
      file: image.file,
      name: data.name,
      path: ERutes.brands,
    });

    dispatch(
      setUpdateBrand({
        name: data.name,
        id: brandStatus.brand.id,
        image: image.updateImage ? imageUrl : brandStatus.brand.image,
      })
    );
    dispatchAsync({
      asyncThuckFuction: updateBrand,
      endPoint: `api/ProductBrand/Update/${brandStatus.brand.id}`,
      body: {
        name: data.name,
        image: image.updateImage ? imageUrl : brandStatus.brand.image,
      },
      method: 'PUT',
    });
    handleCloseModal();
  };

  const handleDeleteBrand = () => {
    dispatch(deleteBrand(brandStatus.brand?.id));
    dispatchAsync({
      asyncThuckFuction: sendDeleteBrand,
      endPoint: `api/ProductBrand/Delete/${brandStatus.brand?.id}`,
      method: 'DELETE',
    });
    handleCloseModal();
  };

  const handleImage = (event: any) => {
    const file = event.target.files[0];

    if (file.type.indexOf('image/') !== -1) {
      setImage({ file, url: URL.createObjectURL(file), updateImage: true });
    }
  };

  return (
    <Modal
      openModal={openModal}
      closeModal={handleCloseModal}
      noCloseButton={true}
      size={{ width: 502, height: 382 }}
    >
      <Form
        onSubmit={ev => {
          ev.preventDefault();
          handleSubmit(brandStatus.addBrand ? createNewBrand : updateOneBrand)();
        }}
      >
        <Title>{brandStatus.addBrand ? 'Agregar marca' : 'Editar marca'}</Title>

        <BrandImage>
          <Image src={image.url} />
          <input type='file' accept='image/*' style={{ display: 'none' }} onChange={handleImage} />
          {errors.image?.message && <ErrorMessage>{errors.image?.message}</ErrorMessage>}
          <EditImage>
            <EditIcon />
          </EditImage>
        </BrandImage>

        <CustomInput2 config={inputsConfig} name='name' label='Nombre' isRequired={true} />

        <Actions>
          <Save type='submit'>
            <SaveIcon />
          </Save>

          {brandStatus.viewBrand && (
            <Delete type='button' onClick={() => handleDeleteBrand()}>
              <DeleteIcon />
            </Delete>
          )}

          <Cancel type='button' onClick={() => handleCloseModal()} editing={brandStatus.viewBrand}>
            <CancelIcon editing={brandStatus.viewBrand} />
          </Cancel>
        </Actions>
      </Form>
    </Modal>
  );
};

const Form = styled.form`
  width: 320px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.1em;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);
  text-align: center;
`;

const Actions = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 12px;
`;

const Save = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--chakra-colors-green-primary);
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const Delete = styled(Save)`
  background: var(--chakra-colors-red-negative);

  &:hover {
    background: var(--chakra-colors-red-negativeHover);
  }
`;

const Cancel = styled(Save)`
  background: var(--chakra-colors-red-negative);

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      background: var(--chakra-colors-yellow-primary);
    `};

  &:hover {
    background: var(--chakra-colors-red-negativeHover);

    ${({ editing }: { editing: boolean }) =>
      editing &&
      css`
        background: var(--chakra-colors-yellow-primaryHover);
      `};
  }
`;

const SaveIcon = styled(FaSave)`
  color: var(--chakra-colors-green-degraded);
  font-size: 16px;
`;

const CancelIcon = styled(IoCloseSharp)`
  color: var(--chakra-colors-white-primary);
  font-size: 20px;

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      color: var(--chakra-colors-black-primary);
    `};
`;

const DeleteIcon = styled(MdDelete)`
  color: var(--chakra-colors-red-degraded);
  font-size: 20px;
`;

const BrandImage = styled.label`
  width: auto;
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
  cursor: pointer;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 8px;
`;

const EditImage = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;
  background: var(--chakra-colors-yellow-primary);
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 9;

  &:hover {
    background: var(--chakra-colors-yellow-primaryHover);
  }
`;

const EditIcon = styled(FaFileUpload)`
  color: var(--chakra-colors-black-primary);
  font-size: 17px;
`;

const ErrorMessage = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(230, 55, 70, 1);
  border-radius: 4px;
  position: absolute;
  bottom: -18px;
  left: 8px;
`;
