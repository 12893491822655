import Icon from '@chakra-ui/icon';
import { Box, Text } from '@chakra-ui/layout';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const StoresHeader = () => {
  const history = useHistory();
  const { currentStore } = useAppSelector(state => state.clientDetails.stores);

  return (
    <>
      <Header>
        <Icon fontSize='1.4em' cursor='pointer' as={BiArrowBack} onClick={() => history.goBack()} />
        <Box ml={2}>
          <Text fontSize='24px' fontWeight='bold'>
            {currentStore?.nombre || 'Crear Tienda'}
          </Text>
          <Text fontWeight='bold' color='var(--chakra-colors-gray-table)'>
            {currentStore?.codigo}
          </Text>
        </Box>
      </Header>
    </>
  );
};
const Header = styled.div`
  display: flex;
  align-items: center;
`;
