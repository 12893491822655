import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState, PackingType } from '../common';

export const sendNewProduct = createAsyncThunk('catalog/addNewProduct', httpService);

export const addNewProductReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendNewProduct.fulfilled, (state, action) => {
    const { productData } = state.createProduct;
    const brandToAdd = state.brands.filter(item => item.id === productData.brandId)[0].name;
    const lineToAdd = state.lines.filter(item => item.id === productData.lineId)[0].name;
    const newProduct = {
      ...productData,
      packingType: { id: productData.packingType, name: PackingType[productData.packingType] },
      brand: { id: productData.brandId, name: brandToAdd },
      line: { id: productData.lineId, name: lineToAdd },
      id: action.payload,
    };

    state.createProduct.status = 'succeeded';
    state.createProduct.message = 'Producto creado';
    state.products.unshift(newProduct);
  });

  builder.addCase(sendNewProduct.pending, state => {
    state.createProduct.status = 'pending';
  });

  builder.addCase(sendNewProduct.rejected, (state, action) => {
    state.createProduct.message = 'Error al crear producto';
    state.createProduct.status = 'failed';
  });
};
