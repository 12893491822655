import { HiArrowNarrowRight } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { Products } from 'components/settings/catalog/ProductDashboard/products/Products';
import { Brands } from 'components/settings/catalog/ProductDashboard/Brands';
import { Lines } from 'components/settings/catalog/ProductDashboard/Lines';

const Dashboard = () => {
  const history = useHistory();
  const { products: allProducts } = useAppSelector(state => state.products);
  const [products, setProducts] = useState(allProducts);

  useEffect(() => {
    if (allProducts.length) {
      setProducts(allProducts.slice(0, 4));
    }
  }, [allProducts]);

  return (
    <Container>
      <ProductsContainer>
        <Products products={products} />
        <ViewButton onClick={() => history.push('/settings/products')}>
          Ver productos
          <ArrowIcon />
        </ViewButton>
      </ProductsContainer>

      <BrandsContainer>
        <Brands />
        <ViewButton onClick={() => history.push('/settings/brands')}>
          Ver marcas
          <ArrowIcon />
        </ViewButton>
      </BrandsContainer>

      <LinesContainer>
        <Lines />
        <ViewButton onClick={() => history.push('/settings/lines')}>
          Ver líneas de productos
          <ArrowIcon />
        </ViewButton>
      </LinesContainer>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  width: calc(100% - 8px);
  max-width: 1200px;
  margin: 24px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 32px;
  row-gap: 24px;

  @media screen and (max-width: 1135px) {
    grid-template-columns: 420px 1fr;
  }
`;

const ProductsContainer = styled.div`
  width: 100%;
  height: auto;
  align-self: start;
  padding: 12px 8px;
  background: var(--chakra-colors-white-primary);
  border-radius: 12px;
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 12px;
  column-gap: 34px;
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  overflow-y: auto;
`;

const ViewButton = styled.button`
  width: auto;
  padding: 2px 6px;
  background: var(--chakra-colors-green-primary);
  color: var(--chakra-colors-green-degraded);
  font-size: 1em;
  font-weight: 700;
  display: grid;
  grid-template-columns: auto 20px;
  column-gap: 8px;
  margin: 0 auto 0 0;
  border-radius: 4px;
  align-items: center;
  transition: 0.3s ease-in-out;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const ArrowIcon = styled(HiArrowNarrowRight)`
  font-size: 20px;
  color: var(--chakra-colors-green-degraded);
  transition: 0.3s ease-in-out;

  ${ViewButton}:hover & {
    transform: translateX(3px);
  }
`;

const BrandsContainer = styled(ProductsContainer)`
  width: calc(100% - 8px);
  align-self: start;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
`;

const LinesContainer = styled(ProductsContainer)`
  width: calc(100% - 8px);
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  align-self: start;
`;
