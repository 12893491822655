import { ColDef } from 'ag-grid-community/main';

export const Columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Nombre',
    sortable: true,
    maxWidth: 200,
  },
  {
    field: 'brand.name',
    headerName: 'Marca',
    sortable: true,
    maxWidth: 160,
  },
  {
    field: 'line.name',
    headerName: 'Linea',
    sortable: true,
    maxWidth: 160,
  },
  {
    field: 'weight',
    headerName: 'Peso',
    sortable: true,
    maxWidth: 120,
  },
  {
    field: 'packingType.name',
    headerName: 'Empaque',
    sortable: true,
    maxWidth: 160,
  },
  {
    field: 'description',
    headerName: 'Nota',
    sortable: true,
    maxWidth: 300,
  },
];
