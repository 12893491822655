import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../../models/Cliente';
import { RootState } from '../../../store/store';
import { extraClientReducers } from './extraReducers/getClientesReducer';
import { calcStatisticsReducer } from './reducer/StatsReducer';

export interface Statistics {
  activos: number;
  inactivos: number;
  total: number;
}

export interface ClienteState {
  status: StatusApp.Status;
  clientes: Client[];
  statistics: Statistics;
  filteredCLientes?: Client[];
}

const initialState: ClienteState = {
  status: 'idle',
  clientes: [],
  statistics: {} as Statistics,
  filteredCLientes: undefined,
};

export const clientesSlice = createSlice({
  name: 'Clientes',
  initialState,
  reducers: {
    calcStatistics: calcStatisticsReducer,
    filterDataTable: (state, action: PayloadAction<string>) => {
      const value = action.payload;

      state.filteredCLientes = state.clientes.filter(cliente =>
        cliente.nombre.toLowerCase().includes(value.toLowerCase())
      );
    },
  },
  extraReducers: builder => {
    extraClientReducers(builder);
  },
});

export const { calcStatistics, filterDataTable } = clientesSlice.actions;

export const selectClientes = (state: RootState) => state.clientes;

export const clientesReducer = clientesSlice.reducer;
