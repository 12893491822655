import { Box, Text } from '@chakra-ui/react';
import { formatToBoolean } from '../../../utils/formatToBoolean';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from './columns';
import { filterDataTable, selectClientes } from '../../../features/Clientes/store/store';
import styled from 'styled-components';
import { AgTable } from '../../common/Table/Table';
import { Search } from '../../common/Search/Search';
import { Client } from '../../../models/Cliente';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RowClickedEvent } from 'ag-grid-community';

export const ClientesTable = () => {
  const { filteredCLientes, status } = useSelector(selectClientes);
  const [search, setSearch] = useState<string>('');
  const dispatch = useDispatch();

  const isRowDisabled = (params: { data: Client }): string => {
    return String(params.data.estado) === 'A' ? 'rowActive' : 'rowInActive';
  };

  const onChange = (value: string) => {
    setSearch(value);
    dispatch(filterDataTable(value));
  };

  const history = useHistory();
  const onRowClicked = (event: RowClickedEvent) => {
    history.push(`/clientes/${event.data.codigoTams}`);
  };
  useEffect(() => {
    return () => {
      dispatch(filterDataTable(''));
    };
  }, [dispatch]);

  return (
    <Container>
      <Box mt={3} ml={2} mr={2}>
        <Search value={search} onChange={onChange} placeHolder='Buscar cliente' />
      </Box>
      <TableContainer height='calc(100vh - 240px)'>
        <AgTable
          getRowClass={isRowDisabled}
          rowData={filteredCLientes}
          pagination={true}
          frameworkComponents={{
            activo: EstadoCLiente,
          }}
          gridOptions={{
            columnDefs: columns,
            defaultColDef: {
              resizable: true,
            },
          }}
          onRowClicked={onRowClicked}
          loading={!filteredCLientes && status === 'pending'}
        />
      </TableContainer>
    </Container>
  );
};

const EstadoCLiente = (statusText: { value: string }) => {
  const status = formatToBoolean(statusText.value);
  return (
    <StateText status={status}>
      <Text>{status ? 'Activo' : 'Inactivo'}</Text>
    </StateText>
  );
};

const StateText = styled.div`
  background-color: ${(props: { status: boolean }) => (props.status ? '#EBF9F5' : '#FDF4F7')};
  color: ${(props: { status: boolean }) => (props.status ? '#00CE84' : '#CD4567')};
  min-width: 40px;
  width: fit-content;
  height: 20px;
  border-radius: 4px;
  padding: 2px 4px !important;
`;

const Container = styled(Box)`
  background-color: var(--chakra-colors-white-primary);
  border-radius: 12px 12px 0 0;
  max-width: 1200px;
  height: 100%;
`;

const TableContainer = styled(Box)`
  background-color: var(--chakra-colors-white-primary);
  border-radius: 12px 12px 0 0;
  /* width: calc(100vw - 24px); */
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  height: calc(100vh - 220px);
`;
