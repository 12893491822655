import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendDeleteLine = createAsyncThunk('lines/deleteLine', httpService);

export const deleteLineReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendDeleteLine.fulfilled, (state, action) => {
    if (action.payload.isDeleted) {
      state.lines = state.lines.filter(line => line.id !== state.lineToDelete.id);
      state.lineToDelete.status = 'succeeded';
      state.lineToDelete.message = 'Marca eliminado';
      return;
    }
    state.lineToDelete.status = 'failed';
    state.lineToDelete.message = action.payload.message;
  });

  builder.addCase(sendDeleteLine.pending, state => {
    state.lineToDelete.status = 'pending';
  });

  builder.addCase(sendDeleteLine.rejected, state => {
    state.lineToDelete.status = 'failed';
    state.lineToDelete.message = 'Error al eliminar marca';
  });
};
