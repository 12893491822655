import { useEffect, useState } from 'react';
import { FaUserCog } from 'react-icons/all';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { updateCLientInfoAction } from './crudAction';
import { useDispatch } from 'react-redux';
import { onSaveChanges, setMessageEmpty } from '../../../features/ClientProfile/store/store';
import { useAppSelector } from 'store/hooks';
import { ClientProfileSchema } from 'schemes/clientProfile';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { CustomAlert } from 'components/common/Alert/Alert';
import { TitleIcon } from 'components/common/TitleIcon/TitleIcon';
import { FormSection } from 'components/common/FormSection/FormSection';
import { CustomInput } from 'components/common/CustomInput/CustomInput';

export type Inputs = {
  nombre: string;
  direccion: string;
  rtn: string;
  telefono1: string;
  correoPrincipal: string;
  cartera: string;
  contacto: string;
  listaPrecio: string;
  giro: string;
};

export const ClientInfo = () => {
  const {
    status,
    message,
    clientDetails: { cliente },
  } = useAppSelector(state => state.clientDetails);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(ClientProfileSchema),
  });

  const [editing, setEditing] = useState<boolean>(false);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    reset(cliente);
  }, [cliente, reset]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    dispatch(onSaveChanges({ ...data, codigoTams: cliente.codigoTams }));
    updateCLientInfoAction(customDispatch, { ...data, idTams: String(cliente.codigoTams) });
    setEditing(false);
  };

  const onSave = () => handleSubmit(onSubmit);

  const onEditCancel = () => {
    reset(cliente);
    setEditing(false);
  };

  const commonProps = {
    editing,
    register,
    errors,
    loading: status === 'pending' ? true : false,
  };

  const onModalCLose = () => {
    dispatch(setMessageEmpty());
  };

  return (
    <InfoContainer>
      <CustomAlert onModalCLose={onModalCLose} description={message} status={status} />

      <TitleIcon text='Información' icon={FaUserCog} />
      <FormSection
        editing={editing}
        setEditing={setEditing}
        title='Información Personal'
        onEditCancel={onEditCancel}
        onSave={onSave()}
        grid={{ gridTemplateColumns: 'repeat(auto-fit,minmax(340px,1fr))' }}
      >
        <CustomInput {...commonProps} isReadOnly={true} name='nombre' label='Nombre' />
        <CustomInput {...commonProps} isRequired={true} name='direccion' label='Dirección' />
        <CustomInput {...commonProps} isReadOnly={true} name='rtn' label='RTN' />
        <CustomInput {...commonProps} name='telefono1' isRequired={true} label='Teléfono' />
        <CustomInput
          {...commonProps}
          name='correoPrincipal'
          isRequired={true}
          label='Correo Principal'
        />
      </FormSection>

      <FormSection
        title='Información General'
        grid={{ gridTemplateColumns: 'repeat(auto-fit,minmax(340px,1fr))' }}
      >
        <CustomInput {...commonProps} isReadOnly={true} name='cartera' label='Cartera' />
        <CustomInput {...commonProps} isRequired={true} name='contacto' label='Contacto' />
        <CustomInput
          {...commonProps}
          isReadOnly={true}
          name='listaPrecio'
          label='Lista de Precios'
        />
        <CustomInput {...commonProps} isReadOnly={true} name='giro' label='Giro' />
      </FormSection>
    </InfoContainer>
  );
};

const InfoContainer = styled.section`
  padding-bottom: 2rem;
`;
