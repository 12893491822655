import { ColorProps, Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface IStatsProps {
  label: string;
  content: string | number;
  color?: ColorProps['color'];
}

export const Statistics = ({ content, label, color }: IStatsProps) => {
  return (
    <StyledStatGroup alignItems='center'>
      <Stat>
        <StatLabel fontWeight='bold'>{label}</StatLabel>
        <StatNumber color={color}>{content}</StatNumber>
      </Stat>
    </StyledStatGroup>
  );
};

const StyledStatGroup = styled(StatGroup)`
  background-color: var(--chakra-colors-white-primary);
  border-radius: 12px;
  width: 220px;
  height: 80px;
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: center;
`;
