import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import { IProductBackend } from '../../../../models/Products';
import httpService from '../../../../services/httpService';
import { IState } from '../common';
import { PackingType } from '../common';

export const getProducts = createAsyncThunk('catalog/getProducts', httpService);

export const getProductsReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(getProducts.fulfilled, (state, action: PayloadAction<IProductBackend[]>) => {
    const brands = current(state.brands);
    state.products = action.payload.map(product => {
      const brandToAdd = brands.filter(item => item.id === product.brandId)[0]?.name;
      const lineToAdd = state.lines.filter(item => item.id === product.lineId)[0]?.name;
      return {
        ...product,
        packingType: { id: product.packingType, name: PackingType[product.packingType] },
        brand: { id: product.brandId, name: brandToAdd },
        line: { id: product.lineId, name: lineToAdd },
      };
    });
    state.status = 'succeeded';
  });

  builder.addCase(getProducts.pending, (state, action) => {
    state.status = 'pending';
  });

  builder.addCase(getProducts.rejected, (state, action) => {
    state.status = 'failed';
  });
};
