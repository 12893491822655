import styled from 'styled-components';
import { RiFilterFill, GrFormClose } from 'react-icons/all';
import { useEffect, useRef, useState } from 'react';
import { Filter } from './Filter';
interface IFilter {
  id: string;
  productLine: string;
}

interface IProps {
  setFilters: any;
  searchProduct: (value: string) => void;
}

const CatalogFinderAndFilter = ({ setFilters, searchProduct }: IProps) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [filter, setFilter] = useState<IFilter>();
  const filterRef = useRef<HTMLHeadingElement>(null);

  // Capture the click outside from all categories
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!filterRef?.current?.contains(event.target)) {
        setOpenFilters(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  const handleFilterSelected = (state: IFilter) => {
    setFilter(state);
    setFilters({ id: state.id, type: 'line' });
  };

  return (
    <Container ref={filterRef}>
      <Input placeholder='Buscar producto' onChange={({ target }) => searchProduct(target.value)} />

      <FilterButton onClick={() => setOpenFilters(!openFilters)}>
        <FilterIcon />
        Filtrar por línea
        <Filter active={openFilters} selectProductLine={handleFilterSelected} />
      </FilterButton>

      {filter?.productLine ? (
        <FilterApplied onClick={() => handleFilterSelected({ productLine: '', id: 'noFilter' })}>
          {filter.productLine}
          <CloseIcon />
        </FilterApplied>
      ) : null}
    </Container>
  );
};

export default CatalogFinderAndFilter;

const Container = styled.header`
  width: 95%;
  max-width: 900px;
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0px auto;
  padding-top: 24px;
  position: relative;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  background: var(--chakra-colors-white-primary);
  border: 1px solid #f0f0f0;
  outline: none;
`;

const FilterButton = styled.div`
  width: auto;
  height: 35px;
  border-radius: 20px;
  padding: 8px;
  font-size: 0.88em;
  color: var(--chakra-colors-black-primary);
  margin-left: 30px;
  background: var(--chakra-colors-yellow-primary);
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const FilterIcon = styled(RiFilterFill)`
  font-size: 18px;
  color: var(--chakra-colors-black-primary);
  margin-right: 4px;
`;

const FilterApplied = styled.button`
  width: auto;
  padding: 4px;
  border: 2px solid var(--chakra-colors-yellow-primary) !important;
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: start;
  font-size: 1em;
  font-weight: 400;
  position: absolute;
  bottom: calc(-65% - 4px);
  right: 0;
  display: grid;
  grid-template-columns: auto 22px;
  column-gap: 8px;
  align-items: center;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(244, 236, 34, 0.1);
  }
`;

const CloseIcon = styled(GrFormClose)`
  font-size: 22px;
  color: var(--chakra-colors-black-primary);
`;
