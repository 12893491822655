import { useState } from 'react';
import NavBar from 'components/settings/NavBar/NavBar';
import Catalog from 'features/Settings/Catalog/ProductDashboard/ProductDashboard';
import { useGetCatalogData } from 'hooks/useGetCatalogData';
import { SalesPerson } from './SalesPerson/SalesPerson';

const optionsDefault = {
  catalog: false,
  clientes: false,
  sellers: false,
};

const Settings = () => {
  useGetCatalogData();
  const [options, setOptions] = useState({ ...optionsDefault, catalog: true });

  return (
    <>
      <NavBar options={options} optionsDefault={optionsDefault} selectOption={setOptions} />
      {options.catalog && <Catalog />}
      {options.sellers && <SalesPerson />}
    </>
  );
};

export default Settings;
