import styled from 'styled-components';
import { FC } from 'react';
import { Navbar } from './Navbar/NavBar';

const LayoutContainer: FC = ({ children }: any) => {
  return (
    <Content>
      <Navbar />
      <section>{children}</section>
    </Content>
  );
};

const Content = styled.div`
  min-height: 100vh;
`;

export default LayoutContainer;
