import { IProduct } from 'models/Products';
import styled from 'styled-components';
import { IMAGE_PLACEHOLDER } from 'utils/imagesOfUtilities';

export const Information = ({ product }: { product: IProduct }) => {
  return (
    <Container>
      <Image src={product.imageUrl || IMAGE_PLACEHOLDER} alt={product.name} />

      <ContentContainer>
        <Title>{product.name}</Title>

        <Text>
          <Span>Marca: </Span>
          {product.brand.name}
        </Text>
        <Text>
          <Span>Linea: </Span>
          {product.line.name}
        </Text>
        <Text>
          <Span>Peso: </Span>
          {product.weight}
        </Text>
        <Text>
          <Span>Empaque: </Span>
          {product.packingType.name}
        </Text>
        <Text>
          <Span>Nota: </Span>
          {product.description.length > 300
            ? `${product.description.slice(0, 300)}...`
            : product.description}
        </Text>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 300px;
  height: 200px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  margin-right: 12px;
`;

const ContentContainer = styled.div`
  width: calc(100% - 312px);
`;

const Title = styled.h3`
  font-size: 1.5em;
  font-weight: 700;
  color: var(--chakra-colors-black-primary);
  display: block;
`;

const Text = styled.p`
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  font-weight: 600;
  display: block;
`;

const Span = styled.span`
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  font-weight: 700;
`;
