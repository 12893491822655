import { Statistics } from 'components/common/Statistics/Statistics';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { calcStatistics, selectClientes } from '../../../features/Clientes/store/store';

export const ClientStats = () => {
  const {
    statistics: { activos, total, inactivos },
    status,
  } = useSelector(selectClientes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(calcStatistics());
  }, [dispatch, status]);
  return (
    <StaticsContainer>
      <Statistics label='Total clientes' content={total} />
      <Statistics label='Clientes inactivos' content={inactivos} color='red.400' />
      <Statistics label='Clientes activos' content={activos} color='green.400' />
    </StaticsContainer>
  );
};

const StaticsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 120px;
  max-width: 768px;
  margin: 0 auto;
`;
