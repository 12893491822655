import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const sendDeleteBrand = createAsyncThunk('brands/deleteBrand', httpService);

export const deleteBrandReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(sendDeleteBrand.fulfilled, (state, action) => {
    if (action.payload.isDeleted) {
      state.brands = state.brands.filter(brand => brand.id !== state.brandToDelete.id);
      state.brandToDelete.status = 'succeeded';
      state.brandToDelete.message = 'Marca eliminado';
      return;
    }
    state.brandToDelete.status = 'failed';
    state.brandToDelete.message = action.payload.message;
  });

  builder.addCase(sendDeleteBrand.pending, state => {
    state.brandToDelete.status = 'pending';
  });

  builder.addCase(sendDeleteBrand.rejected, state => {
    state.brandToDelete.status = 'failed';
    state.brandToDelete.message = 'Error al eliminar marca';
  });
};
