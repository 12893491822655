import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { IProduct } from 'models/Products';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormSection } from 'components/common/FormSection/FormSection';
import { CustomInput2 } from 'components/common/CustomInput2/CustomInput2';
import { CustomSelect } from 'components/common/CustomSelect/CustomSelect';
import { ProductSchema } from 'schemes/Product';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { sendNewProduct } from 'features/Catalog/Store/extraReducers/createProduct';
import { addNewProduct } from 'features/Catalog/Store/store';
import { useAppSelector } from 'store/hooks';
import { useGetCatalogData } from 'hooks/useGetCatalogData';
import { ERutes, uploadImage } from 'services/uploadFile';
import { FaFileUpload } from 'react-icons/fa';
import { IMAGE_PLACEHOLDER } from 'utils/imagesOfUtilities';

const packingTypeOptions = [
  { id: 0, name: 'Termoformado' },
  { id: 1, name: 'Bolsa' },
  { id: 2, name: 'BolsaAlVacio' },
];

const CreateProduct = () => {
  useGetCatalogData();
  const history = useHistory();
  const { brands, lines } = useAppSelector(state => state.products);
  const dispatchAsync = useCustomDispatch();
  const dispatch = useDispatch();
  const [image, setImage] = useState({ file: File, url: IMAGE_PLACEHOLDER });
  const [brandsOptions, setBrandsOptions] = useState([{ id: '', name: '' }]);

  useEffect(() => {
    if (brands.length) {
      const allBrands = brands.map(brand => {
        return { id: brand.id, name: brand.name };
      });
      setBrandsOptions(allBrands);
    }
  }, [brands]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<IProduct>({
    resolver: yupResolver(ProductSchema),
  });

  const inputsConfig = {
    register,
    errors,
    getValues,
    setValue,
    setError,
  };

  const onSubmit = async (data: IProduct) => {
    if (image.file.name === 'File') {
      setError('imageUrl', { message: 'Imagen requerida' });
      return;
    }

    setError('imageUrl', { message: '' });
    const imageUrl = await uploadImage({
      file: image.file,
      name: data.name,
      path: ERutes.products,
    });
    dispatch(addNewProduct({ ...data, imageUrl: `${imageUrl}`, videoUrl: '', imageGallery: [] }));
    dispatchAsync({
      asyncThuckFuction: sendNewProduct,
      endPoint: 'api/Catalog/Create',
      body: {
        name: data.name,
        imageUrl,
        weight: data.weight,
        packingType: data.packingType.id,
        brandId: data.brand.id,
        productLineId: data.line.id,
        description: data.description,
        videoUrl: '',
        imageGallery: [],
      },
      method: 'POST',
    });
    history.push('/settings/products');
  };

  const handleImage = (event: any) => {
    const file = event.target.files[0];

    if (file.type.indexOf('image/') !== -1) {
      setImage({ file, url: URL.createObjectURL(file) });
    }
  };

  return (
    <Container>
      <ArrowBack onClick={() => history.goBack()} />

      <ImageContainer>
        <Image src={image.url} />
        <input type='file' accept='image/*' style={{ display: 'none' }} onChange={handleImage} />
        {errors.imageUrl?.message && <ErrorMessage>{errors.imageUrl?.message}</ErrorMessage>}
        <EditImage>
          <EditIcon />
        </EditImage>
      </ImageContainer>

      <FormContainer>
        <FormSection
          title='Información'
          editing={true}
          onEditCancel={() => history.goBack()}
          onSave={() => handleSubmit(onSubmit)()}
          grid={{ templateColumns: '1fr' }}
        >
          <CustomInput2 config={inputsConfig} name='name' label='Nombre' />
          <CustomSelect
            config={inputsConfig}
            name='brand'
            options={brandsOptions || brandsOptions}
            label='Marca'
          />
          <CustomSelect
            config={inputsConfig}
            name='line'
            options={lines || brandsOptions}
            label='Linea'
          />
          <CustomSelect
            config={inputsConfig}
            name='packingType'
            options={packingTypeOptions}
            label='Empaque'
          />
          <CustomInput2 config={inputsConfig} name='weight' label='Peso' />
          <CustomInput2 config={inputsConfig} name='description' label='Nota de producto' />
        </FormSection>
      </FormContainer>
    </Container>
  );
};

export default CreateProduct;

const Container = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ArrowBack = styled(BiArrowBack)`
  font-size: 24px;
  color: var(--chakra-colors-black-primary);
  margin: 0 auto 0 8px;
  font-weight: 700;
  cursor: pointer;
`;

const ImageContainer = styled.label`
  width: 300px;
  margin: 12px auto 0 auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const Image = styled.img`
  width: 300px;
  height: 200px;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
`;

const FormContainer = styled.div`
  width: 95%;
  max-width: 800px;
  margin: 36px auto 0 auto;
`;

const EditImage = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;
  background: var(--chakra-colors-yellow-primary);
  position: absolute;
  right: -10px;
  bottom: -10px;
  z-index: 9;

  &:hover {
    background: var(--chakra-colors-yellow-primaryHover);
  }
`;

const EditIcon = styled(FaFileUpload)`
  color: var(--chakra-colors-black-primary);
  font-size: 17px;
`;

const ErrorMessage = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  color: rgba(230, 55, 70, 1);
  border-radius: 4px;
  position: absolute;
  bottom: -18px;
  left: 8px;
`;
