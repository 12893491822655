import { yupResolver } from '@hookform/resolvers/yup';
import { CustomAlert } from 'components/common/Alert/Alert';
import { CustomInput } from 'components/common/CustomInput/CustomInput';
import { FormSection } from 'components/common/FormSection/FormSection';
import { IPosition } from 'components/common/Map/Map';
import { createStore } from 'features/ClientProfile/store/extraReducers/CreateStore';
import { updateStore } from 'features/ClientProfile/store/extraReducers/UpdateStore';
import {
  IPostStore,
  setCurrentStore,
  setMessageEmpty,
  setModifiedPosition,
  setModifiedStore,
  setViewMap,
} from 'features/ClientProfile/store/store';
import { UseCustomDispatch, useCustomDispatch } from 'hooks/useCustomDispatch';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { storesClientSchema } from 'schemes/storesCLient';
import { useAppSelector } from 'store/hooks';
import { searchMaxNumber } from 'utils/maxNumber';
import { StoresMap } from '../StoresMap/StoresMap';

export interface IStoresForm {
  nombre: string;
  direccion: string;
  telefono: string;
  contacto: string;
  latitude: string;
  longitude: string;
}

export const StoresForm = () => {
  const {
    stores: {
      currentStore,
      action,
      status,
      modifiedStore,
      map: { modifiedLocalization },
    },
    clientDetails: { cliente, sucursales },
    message,
  } = useAppSelector(state => state.clientDetails);

  const { idStore } = useParams<{ idStore: string | undefined }>();
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState<boolean>(true);
  const history = useHistory();
  const {
    reset,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<IStoresForm>({
    resolver: yupResolver(storesClientSchema),
    mode: 'all',
  });

  useEffect(() => {
    dispatch(setCurrentStore({ id: Number(idStore) || modifiedStore.id }));
  }, [dispatch, idStore, modifiedStore.id]);

  useEffect(() => {
    const setSavedPosition = () => {
      if (action === 'updating') {
        const { codigo, images, ...rest } = currentStore;
        reset(rest);
        dispatch(
          setModifiedPosition({
            lat: Number(currentStore?.latitude),
            lng: Number(currentStore?.longitude),
          })
        );
      }
    };
    setSavedPosition();
  }, [action, currentStore, dispatch, reset]);

  const onSubmit: SubmitHandler<IStoresForm> = values => {
    setEditing(false);

    const dtoStores = {
      ...values,
      idCliente: String(cliente.codigoTams),
      id:
        action === 'creating'
          ? searchMaxNumber(sucursales.map(suc => suc.codigo)) + 1
          : currentStore.codigo,
      latitude: String(modifiedLocalization?.lat),
      longitude: String(modifiedLocalization?.lng),
      images: [],
    } as unknown as IPostStore;

    dispatch(setModifiedStore({ ...dtoStores, images: [] }));

    const sendDataByAction: { [key: string]: UseCustomDispatch } = {
      creating: {
        asyncThuckFuction: createStore,
        endPoint: 'api/Clientes/AddSucursal',
        body: { ...dtoStores, id: searchMaxNumber(sucursales.map(suc => suc.codigo)) + 1 },
      },
      updating: {
        asyncThuckFuction: updateStore,
        endPoint: 'api/Clientes/UpdateSucursal',
        body: { ...dtoStores, id: currentStore.codigo },
        method: 'PUT',
      },
    };
    customDispatch(sendDataByAction[action]);
  };

  const onSave = () => handleSubmit(onSubmit);

  const onCancel = () => {
    if (action === 'creating') {
      history.goBack();
      return;
    }
    setEditing(false);
    reset(currentStore);
    const resetMapPosition = (value: IPosition) => {
      dispatch(setViewMap(value));
      dispatch(setModifiedPosition(value));
    };
    action === 'updating' &&
      resetMapPosition({
        lat: Number(currentStore.latitude),
        lng: Number(currentStore.longitude),
      });
  };

  const onModalCLose = () => {
    dispatch(setMessageEmpty());
  };

  const commonProps = {
    errors,
    editing,
    register,
    isRequired: editing,
  };

  return (
    <>
      <CustomAlert onModalCLose={onModalCLose} description={message} status={status} />
      <FormSection
        title='Información'
        setEditing={setEditing}
        editing={editing}
        onSave={onSave()}
        onEditCancel={onCancel}
      >
        <CustomInput {...commonProps} name='nombre' label='Nombre' />
        <CustomInput {...commonProps} name='direccion' label='Dirección' />
        <CustomInput {...commonProps} name='telefono' label='Teléfono' />
        <CustomInput {...commonProps} name='contacto' label='Contacto' />
        <StoresMap editing={editing} />
      </FormSection>
    </>
  );
};
