import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { Table } from 'components/settings/catalog/Brands/Table/Table';
import { CreateAndEditBrand } from 'components/settings/catalog/Brands/CreateAndEditBrand';
import { useAppSelector } from 'store/hooks';
import { useGetCatalogData } from 'hooks/useGetCatalogData';
import { CustomAlert } from 'components/common/Alert/Alert';
import { useDispatch } from 'react-redux';
import { resetBrandsStatus } from 'features/Catalog/Store/store';

interface IBrand {
  id: any;
  name: string;
  image: string;
}

const Brands = () => {
  useGetCatalogData();
  const dispatch = useDispatch();
  const [brandStatus, setBrandSelected] = useState({
    brand: {} as IBrand,
    viewBrand: false,
    addBrand: false,
  });
  const {
    brands: allBrands,
    brandToDelete,
    newBrand,
    updateBrand,
  } = useAppSelector(state => state.products);
  const [brands, setBrands] = useState(allBrands);
  const history = useHistory();

  const handleSearch = (toSearch: string) => {
    if (toSearch.length) {
      const brandsResult: IBrand[] = [];

      allBrands.forEach(brand => {
        if (brand.name.toLowerCase().includes(toSearch.toLowerCase())) {
          brandsResult.push(brand);
        }
      });

      setBrands(brandsResult);
      return;
    }
    setBrands(allBrands);
  };

  useEffect(() => {
    if (allBrands.length) {
      setBrands(allBrands);
      return;
    }
    setBrands([]);
  }, [allBrands]);

  const handleBrandSelected = (id: string) => {
    const index = brands.findIndex(brand => brand.id === id);
    setBrandSelected({ ...brandStatus, brand: brands[index], viewBrand: true });
  };

  return (
    <Container>
      <CustomAlert
        description={brandToDelete.message}
        status={brandToDelete.status}
        onModalCLose={() => dispatch(resetBrandsStatus())}
      />
      <CustomAlert
        description={newBrand.message}
        status={newBrand.status}
        onModalCLose={() => dispatch(resetBrandsStatus())}
      />
      <CustomAlert
        description={updateBrand.message}
        status={updateBrand.status}
        onModalCLose={() => dispatch(resetBrandsStatus())}
      />

      <ArrowBack onClick={() => history.goBack()} />
      <Search placeholder='Buscar marca' onChange={({ target }) => handleSearch(target.value)} />

      <AddContainer>
        <AddBrand onClick={() => setBrandSelected({ ...brandStatus, addBrand: true })}>
          Agregar marca
        </AddBrand>
      </AddContainer>

      <Table brands={brands} viewBrand={(id: string) => handleBrandSelected(id)} />

      <CreateAndEditBrand
        openModal={brandStatus.addBrand || brandStatus.viewBrand}
        closeModal={setBrandSelected}
        brandStatus={brandStatus}
      />
    </Container>
  );
};

export default Brands;

const Container = styled.div`
  width: calc(100% - 24px);
  max-width: 1200px;
  margin: 12px auto 0 auto;
  height: 100%;
  background: var(--chakra-colors-white-primary);
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
`;

const Search = styled.input`
  margin: 24px auto 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 8px;
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  background: #f5f8fa;
  border-radius: 8px;
  outline: none;

  &::placeholder {
    font-size: 1em;
    color: var(--chakra-colors-gray-primary);
  }
`;

const AddBrand = styled.button`
  padding: 2px 4px;
  font-size: 0.8em;
  color: var(--chakra-colors-green-degraded);
  background: var(--chakra-colors-green-primary);
  font-weight: 700;
  border-radius: 4px;
  transition: 0.3s ease-in-out;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
  &:focus {
    outline: none;
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const AddContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin: 24px auto 12px auto;
`;

const ArrowBack = styled(BiArrowBack)`
  font-size: 24px;
  color: var(--chakra-colors-black-primary);
  margin: 8px auto 0 8px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: translateX(-3px);
  }
`;
