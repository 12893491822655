import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { ClienteProfileState } from '../store';

export const updateStore = createAsyncThunk('update/ClientStores', httpService);

export const updateStoreReducer = (builder: ActionReducerMapBuilder<ClienteProfileState>) => {
  builder.addCase(updateStore.fulfilled, (state, action) => {
    if (!action.payload) {
      state.message = 'No se pudo actualizar los datos, revisa tu conexión';
      state.stores.status = 'failed';
      return;
    }
    const index = state.clientDetails.sucursales.findIndex(
      sucursal => sucursal.codigo === state.stores.currentStore.codigo
    );
    state.clientDetails.sucursales[index] = {
      ...state.stores.modifiedStore,
      codigo: state.stores.modifiedStore.id,
    };
    state.message = 'Actualizado con éxito';
    state.stores.status = 'succeeded';
  });

  builder.addCase(updateStore.pending, state => {
    state.stores.status = 'pending';
  });

  builder.addCase(updateStore.rejected, state => {
    state.message = 'No se pudo actualizar los datos, revisa tu conexión';
    state.stores.status = 'failed';
  });
};
