import { CircularProgress } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { IProduct } from 'models/Products';
import { useAppSelector } from 'store/hooks';
import { ProductCard } from './productCard';
import { Container } from 'components/common/Container/Container';
import { IMAGE_NO_DATA } from 'utils/imagesOfUtilities';

interface IProps {
  filterByBrand: number | string;
  filterByLine: number | string;
  toSearch: string;
}

const CatalogProducts = ({ filterByBrand, filterByLine, toSearch }: IProps) => {
  const {
    products: allProducts,
    status,
    brands,
    lines,
    brandsStatus,
    linesStatus,
  } = useAppSelector(state => state.products);
  const [products, setProducts] = useState(allProducts);
  const [dataStatus, setDataStatus] = useState({ loading: true, noData: false });

  useEffect(() => {
    if (
      brandsStatus === 'idle' ||
      brandsStatus === 'pending' ||
      linesStatus === 'idle' ||
      linesStatus === 'pending'
    ) {
      setDataStatus({ loading: true, noData: false });
      return;
    }

    if (lines.length && brands.length && (status === 'idle' || status === 'pending')) {
      setDataStatus({ loading: true, noData: false });
      return;
    }

    if (!lines.length || !brands.length || !products.length) {
      setDataStatus({ loading: false, noData: true });
      return;
    }

    setDataStatus({ loading: false, noData: false });
  }, [products, status, brands, lines, brandsStatus, linesStatus]);

  useEffect(() => {
    if (filterByBrand !== 'noFilter' && filterByLine === 'noFilter') {
      setProducts(allProducts.filter(product => product.brand.id === filterByBrand));
      return;
    }

    if (filterByLine !== 'noFilter' && filterByBrand === 'noFilter') {
      setProducts(allProducts.filter(product => product.line.id === filterByLine));
      return;
    }

    if (filterByLine !== 'noFilter' && filterByBrand !== 'noFilter') {
      setProducts(
        allProducts.filter(
          product => product.line.id === filterByLine && product.brand.id === filterByBrand
        )
      );
      return;
    }
    setProducts(allProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByBrand, filterByLine, allProducts]);

  useEffect(() => {
    if (toSearch.length) {
      const productsResult: IProduct[] = [];

      allProducts.forEach(product => {
        if (product.name.toLowerCase().includes(toSearch.toLowerCase())) {
          productsResult.push(product);
        }
      });

      setProducts(productsResult);
      return;
    }
    setProducts(allProducts);
  }, [toSearch, allProducts]);

  return (
    <CustomContainer isLoading={dataStatus.loading || dataStatus.noData}>
      {dataStatus.loading && !dataStatus.noData ? (
        <CircularProgress isIndeterminate={true} color='var(--chakra-colors-yellow-primary)' />
      ) : null}

      {!dataStatus.loading && !dataStatus.noData
        ? products.map(product => <ProductCard key={product.id} product={product} />)
        : null}

      {!dataStatus.loading && dataStatus.noData && <NoDataMessage src={IMAGE_NO_DATA} />}
    </CustomContainer>
  );
};

export default CatalogProducts;

const CustomContainer = styled(Container)`
  display: grid;
  margin-top: 34px;
  column-gap: 30px;
  row-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 600px);
      flex-direction: column;
    `};
`;

const NoDataMessage = styled.img`
  width: 150px;
  margin: auto;
  grid-column: 1 / -1;
`;
