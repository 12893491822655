import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { TableContainer } from 'components/common/TableContainer/TableContainer';
import { AgTable } from '../../../common/Table/Table';
import './table.css';

interface IContacsTable {
  onRowClicked: (event: RowClickedEvent) => void;
  data: any[];
  status: StatusApp.Status;
  columns: ColDef[];
}

export const ContacsTable = ({ data, onRowClicked, status, columns }: IContacsTable) => {
  return (
    <TableContainer>
      <AgTable
        rowData={data}
        pagination={true}
        gridOptions={{
          columnDefs: columns,
          defaultColDef: {
            resizable: true,
            suppressAutoSize: true,
          },
        }}
        onRowClicked={onRowClicked}
        loading={status === 'pending' ? true : false}
      />
    </TableContainer>
  );
};
