import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Tab } from 'components/common/Tab/Tab';
import { FaUserCog } from 'react-icons/fa';
import { MdContactPhone } from 'react-icons/md';
import { useAppSelector } from 'store/hooks';
import { EStoresNav } from '../StoresInfo';

interface IStoresNav {
  section: EStoresNav;
  setSection: (ti: EStoresNav) => void;
}

export const StoresNav = ({ section, setSection }: IStoresNav) => {
  const { action } = useAppSelector(state => state.clientDetails.stores);
  return (
    <CardContainer>
      <Tab
        text='Información'
        selected={section}
        to={EStoresNav.informacion}
        setSection={setSection}
        icon={FaUserCog}
      />
      {action === 'updating' && (
        <Tab
          text='Galería'
          selected={section}
          to={EStoresNav.geleria}
          setSection={setSection}
          icon={MdContactPhone}
        />
      )}
    </CardContainer>
  );
};

const CardContainer = styled(Flex)`
  height: 100px;
  width: 100%;
  margin-top: 12px;
`;
