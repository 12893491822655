import { AgTable } from 'components/common/Table/Table';
import { Columns } from './colums';
import styled from 'styled-components';

interface ILine {
  id: any;
  name: string;
}

interface IProps {
  viewLine: (value: ILine) => void;
  lines: ILine[];
}

export const Table = ({ lines, viewLine }: IProps) => {
  return (
    <Container>
      <AgTable
        loading={false}
        rowData={lines}
        gridOptions={{ columnDefs: Columns }}
        onRowClicked={event => viewLine(event.data)}
        pagination={true}
      />
    </Container>
  );
};

const Container = styled.div`
  width: calc(100vw - 24px);
  max-width: 820px;
  margin: 0 auto;
  display: flex;
  height: calc(100vh - 240px);

  & .ag-header-cell {
    width: 100%;
  }

  & .ag-row {
    width: 100% !important;
    cursor: pointer;
  }

  & .ag-center-cols-container {
    width: 100% !important;
  }
`;
