import { useState } from 'react';

import { getDownloadURL, ref, storage, uploadBytesResumable } from '../firebase/storageConfig';

export enum ERutes {
  stores = 'Stores/',
  products = 'Products/',
  brands = 'Brands/',
}

interface IUploadImage {
  file: any;
  path: ERutes;
  name: string;
}

export const useUploadFile = () => {
  const [fileStatus, setFileStatus] = useState({
    url: '',
    progress: 0,
    error: false,
    loading: false,
  });

  const sendFile = ({ file, name, path }: IUploadImage) => {
    const storageRef = ref(storage, `${path}${name}${Date.now()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileStatus({ url: '', progress: Math.round(progress), error: false, loading: true });
      },
      () => {
        setFileStatus({ url: '', progress: 0, error: true, loading: false });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          setFileStatus({ url: downloadURL, progress: 0, error: false, loading: false });
        });
      }
    );
  };

  return { fileStatus, sendFile };
};
