import { app } from './firebase';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  getMetadata,
  deleteObject,
  uploadBytesResumable,
} from 'firebase/storage';
const storage = getStorage(app);

export {
  storage,
  getDownloadURL,
  ref,
  uploadBytes,
  getMetadata,
  deleteObject,
  uploadBytesResumable,
};
