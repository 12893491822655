import { Grid, GridProps, useMediaQuery, TypographyProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { EditControls } from '../EditControls/EditControls';
import { Title } from '../Title/Title';

interface IFormSection {
  title: string;
  onSave?: () => void;
  onEditCancel?: () => void;
  setEditing?: (value: boolean) => void;
  editing?: boolean;
  grid?: GridProps;
  titlePosition?: TypographyProps['textAlign'];
}

export const FormSection: FC<IFormSection> = ({
  title,
  setEditing = () => '',
  editing,
  onEditCancel = () => '',
  onSave,
  children,
  grid,
  titlePosition,
}) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Section>
      <Title textAlign={titlePosition}>{title}</Title>
      {onSave && (
        <EditControls
          setEditing={setEditing}
          onSave={onSave}
          editing={Boolean(editing)}
          onEditCancel={onEditCancel}
        />
      )}
      <Grid
        mt={3}
        templateColumns={`repeat(auto-fit,minmax(${isLargerThan768 ? '400px' : '1fr'},1fr))`}
        {...grid}
        gap={6}
      >
        {children}
      </Grid>
    </Section>
  );
};

const Section = styled.div`
  background-color: var(--chakra-colors-white-primary);
  border-radius: 12px;
  height: fit-content;
  padding: 1.5rem 1rem;
  margin-top: 0.8rem;
  position: relative;
`;
