import styled, { css } from 'styled-components';
import { FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { IoCloseSharp } from 'react-icons/io5';
import { sendNewLine } from 'features/Catalog/Store/extraReducers/createLine';
import { updateLine } from 'features/Catalog/Store/extraReducers/updateLine';
import { createLine, deleteLine, setUpdateLine } from 'features/Catalog/Store/store';
import { useEffect } from 'react';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { CustomInput2 } from 'components/common/CustomInput2/CustomInput2';
import { Modal } from 'components/common/Modal/Modal';
import { sendDeleteLine } from 'features/Catalog/Store/extraReducers/deleteLine';
import { MdDelete } from 'react-icons/md';
import { useAppSelector } from 'store/hooks';

type ILine = {
  name: string;
  id: string;
};

type ILineForm = {
  name: string;
};

interface IProps {
  openModal: boolean;
  closeModal: any;
  lineStatus: { line: ILine; addLine: boolean; viewLine: boolean };
}

export const CreateAndEditLine = ({ openModal, closeModal, lineStatus }: IProps) => {
  const dispatch = useDispatch();
  const dispatchAsync = useCustomDispatch();
  const { lines } = useAppSelector(state => state.products);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm<ILineForm>();

  const inputsConfig = {
    register,
    errors,
    getValues,
    setValue,
    setError,
  };

  useEffect(() => {
    if (lineStatus.line?.id) {
      reset({ name: lineStatus.line.name });
    }
  }, [lineStatus, reset]);

  const handleCloseModal = () => {
    closeModal({ line: {} as ILine, addLine: false, viewLine: false });
    reset({ name: '' });
  };

  const createNewLine = (data: ILineForm) => {
    if (lines.some(line => line.name.toLowerCase() === data.name.toLowerCase())) {
      setError('name', { message: 'Ya existe una linea con ese nombre' });
      return;
    }

    dispatch(createLine({ name: data.name }));
    dispatchAsync({
      asyncThuckFuction: sendNewLine,
      endPoint: 'api/ProductLine/Create',
      body: {
        name: data.name,
      },
      method: 'POST',
    });
    handleCloseModal();
  };

  const updateOneLine = (data: ILineForm) => {
    if (lines.some(line => line.name.toLowerCase() === data.name.toLowerCase())) {
      setError('name', { message: 'Ya existe una linea con ese nombre' });
      return;
    }

    dispatch(setUpdateLine({ name: data.name, id: lineStatus.line.id }));
    dispatchAsync({
      asyncThuckFuction: updateLine,
      endPoint: `api/ProductLine/Update/${lineStatus.line.id}`,
      body: {
        name: data.name,
      },
      method: 'PUT',
    });
    handleCloseModal();
  };

  const handleDeleteLine = () => {
    dispatch(deleteLine(lineStatus.line?.id));
    dispatchAsync({
      asyncThuckFuction: sendDeleteLine,
      endPoint: `api/ProductLine/Delete/${lineStatus.line?.id}`,
      method: 'DELETE',
    });
    handleCloseModal();
  };

  return (
    <Modal
      openModal={openModal}
      closeModal={handleCloseModal}
      noCloseButton={true}
      size={{ width: 502, height: 180 }}
    >
      <Form
        onSubmit={ev => {
          ev.preventDefault();
          handleSubmit(lineStatus.addLine ? createNewLine : updateOneLine)();
        }}
      >
        <Title>Agregar nueva línea</Title>

        <CustomInput2 config={inputsConfig} name='name' label='Nombre' isRequired={true} />

        <Actions>
          <Save type='submit'>
            <SaveIcon />
          </Save>

          {lineStatus.viewLine && (
            <Delete type='button' onClick={() => handleDeleteLine()}>
              <DeleteIcon />
            </Delete>
          )}

          <Cancel type='button' onClick={handleCloseModal} editing={lineStatus.viewLine}>
            <CancelIcon editing={lineStatus.viewLine} />
          </Cancel>
        </Actions>
      </Form>
    </Modal>
  );
};

const Form = styled.form`
  width: 320px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.1em;
  font-weight: 700;
  color: var(--chakra-colors-gray-table);
  text-align: center;
`;

const Actions = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 12px;
`;

const Save = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--chakra-colors-green-primary);
  border-radius: 50%;
  outline: none;
  transition: 0.3s ease-in-out;

  &:hover {
    background: var(--chakra-colors-green-primaryHover);
  }
`;

const Delete = styled(Save)`
  background: var(--chakra-colors-red-negative);

  &:hover {
    background: var(--chakra-colors-red-negativeHover);
  }
`;

const Cancel = styled(Save)`
  background: var(--chakra-colors-red-negative);

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      background: var(--chakra-colors-yellow-primary);
    `};

  &:hover {
    background: var(--chakra-colors-red-negativeHover);

    ${({ editing }: { editing: boolean }) =>
      editing &&
      css`
        background: var(--chakra-colors-yellow-primaryHover);
      `};
  }
`;

const SaveIcon = styled(FaSave)`
  color: var(--chakra-colors-green-degraded);
  font-size: 16px;
`;

const CancelIcon = styled(IoCloseSharp)`
  color: var(--chakra-colors-white-primary);
  font-size: 20px;

  ${({ editing }: { editing: boolean }) =>
    editing &&
    css`
      color: var(--chakra-colors-black-primary);
    `};
`;

const DeleteIcon = styled(MdDelete)`
  color: var(--chakra-colors-red-degraded);
  font-size: 20px;
`;
