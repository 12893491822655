interface IBase64ToFile {
  base64: string;
  name: string;
}

export const base64ToFile = async ({ base64, name: filename }: IBase64ToFile) => {
  const res = await fetch(base64);
  const blob = await res.blob();
  return new File([blob], filename, { type: 'image/webp' });
};
