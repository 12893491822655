import { Container } from 'components/common/Container/Container';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useEffect } from 'react';
import styled from 'styled-components';
import { SalesForm } from '../../../components/settings/SalesPerson/SalesForm/SalesForm';
import { SalesTable } from '../../../components/settings/SalesPerson/SalesTable/SalesTable';
import { getSalesPersons } from './Store/extraReducers/GetSalesPersons';

export const SalesPerson = () => {
  const customDispatch = useCustomDispatch();

  useEffect(() => {
    customDispatch({
      asyncThuckFuction: getSalesPersons,
      endPoint: 'api/SalesPerson/GetAll',
    });
  }, [customDispatch]);

  return (
    <SalesPersonContainer>
      <SalesTable />
      <SalesForm />
    </SalesPersonContainer>
  );
};

const SalesPersonContainer = styled(Container)`
  margin-top: 24px;
  border-radius: 12px;
`;
