import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@chakra-ui/react';
import { GridApi } from 'ag-grid-community';
import './variables.css';
import styled from 'styled-components';
import { localeEs } from './locale';

interface ITable extends AgGridReactProps {
  /**
   * @param Use  loanding
   * @type boolean
   * @description si es `true` se muestra el Loader
   */
  loading?: boolean;
}

const Loader = () => (
  <>
    {' '}
    <CircularProgress isIndeterminate={true} color='var(--chakra-colors-yellow-primary)' />{' '}
  </>
);

export const AgTable: FC<ITable> = ({
  rowData,
  children,
  frameworkComponents,
  loading,
  ...rest
}) => {
  const [gridApi, setGridApi] = useState<GridApi>();

  useEffect(() => {
    if (loading) {
      gridApi?.showLoadingOverlay();
    }

    if (loading === false && Number(rowData?.length) === 0) {
      gridApi?.showNoRowsOverlay();
    }
  }, [gridApi, loading, rowData?.length]);

  return (
    <Container>
      <div className='ag-theme-material' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowClass='row'
          headerHeight={50}
          autoSizePadding={6}
          {...rest}
          frameworkComponents={{
            ...frameworkComponents,
            loadingOverlayComponent: Loader,
            noRowsOverlayComponent: (props: any) => <p>No hay Datos para mostrar</p>,
          }}
          loadingOverlayComponent={'loadingOverlayComponent'}
          noRowsOverlayComponent={'noRowsOverlayComponent'}
          rowData={rowData}
          onGridReady={params => {
            setGridApi(params.api);
          }}
          localeText={localeEs}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;
