import styled, { keyframes, css } from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

type option = { id: number | string; name: string };

interface IProps {
  options: option[];
  optionSelectedDefault?: option;
  sendSelectedOption: (value: option) => void;
  disabled?: boolean;
  clear: boolean;
}

export const SelectComponent = ({
  options,
  optionSelectedDefault,
  sendSelectedOption,
  disabled = false,
  clear,
}: IProps) => {
  const [optionSelected, setOptionSelected] = useState(optionSelectedDefault);
  const [activeSelect, setActiveSelect] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // clear
  useEffect(() => {
    if (clear) {
      setActiveSelect(false);
      setOptionSelected(optionSelectedDefault || undefined);
    }
  }, [clear, optionSelectedDefault]);

  // set default value
  useEffect(() => {
    if (optionSelectedDefault) {
      setOptionSelected(optionSelectedDefault);
    }
  }, [optionSelectedDefault]);

  // Close select when you lose focus
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setActiveSelect(false);
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef]);

  // Send option selected, view and close select
  const viewOptionSelected = (data: option) => {
    setOptionSelected(data);
    sendSelectedOption(data);
    setActiveSelect(false);
  };

  return (
    <Container ref={wrapperRef}>
      <Select
        onClick={() => {
          if (!disabled) {
            setActiveSelect(!activeSelect);
          }
        }}
        disabled={disabled}
      >
        <SelectedOption>
          <TextContainer>
            <Text>{optionSelected?.name || ''}</Text>
          </TextContainer>
        </SelectedOption>

        <DeploymentArrow>
          <Arrow />
        </DeploymentArrow>
      </Select>

      <OptionsContainer active={activeSelect}>
        {options.map(({ name, id }: option, index) => (
          <Option onClick={() => viewOptionSelected({ id, name })} key={index}>
            <TextContainer>
              <Text>{name}</Text>
            </TextContainer>
          </Option>
        ))}
      </OptionsContainer>
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    transform: translateY(-40px) scale(.5);
  }

  to {
    transform: translateY(4px) scale(1);
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const Text = styled.p`
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  font-weight: 400;
`;

const Arrow = styled(RiArrowDropDownLine)`
  font-size: 20px;
  color: var(--chakra-colors-black-primary);
`;

const Select = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 8px 4px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
  background: var(--chakra-colors-background-gray);

  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    css`
      cursor: default;
      border: 1px solid #f0f0f0;
      background: var(--chakra-colors-white-primary);
    `};
`;

const SelectedOption = styled.div`
  width: 100%;
  align-self: center;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const TextContainer = styled.div`
  align-self: center;
`;

const DeploymentArrow = styled.div`
  width: 24px;
  height: 24px;
  align-self: center;
`;

const OptionsContainer = styled.div`
  width: 100%;
  position: absolute;
  padding: 4px;
  overflow: auto;
  z-index: 99999999999999999999999;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: #ffff;
  display: none;
  max-height: 400%;
  overflow-y: auto;

  ${({ active }: { active: boolean }) =>
    active &&
    css`
      display: block;
      animation: ${fadeIn} 0.3s forwards;
    `};
`;

const Option = styled.div`
  width: 100%;
  background: #ffff;
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  margin: 0 auto 4px auto;
  border: 1px solid #f0f0f0;

  &:hover {
    background: #fefdec;
  }
`;
