import { getDownloadURL, ref, storage, uploadBytes } from '../firebase/storageConfig';
import { base64ToFile } from 'utils/Base64ToFile';

export enum ERutes {
  stores = 'Stores/',
  products = 'Products/',
  brands = 'Brands/',
}

export interface IUploadImage {
  base64: string;
  path: ERutes;
  name: string;
}

export const uploadBase64 = async ({ base64, name, path }: IUploadImage) => {
  const file = await base64ToFile({ base64, name });

  const storageRef = ref(storage, `${path}${name}${Date.now()}.webp`);
  const snapshot = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(snapshot.ref);
  return url;
};
