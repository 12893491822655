import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SalesPerson } from 'models/SalesPersons';
import httpService from 'services/httpService';
import { SalesSettingsState } from '../store';

export const getSalesPersons = createAsyncThunk('get/SalesPersons', httpService);

export const getSalesPersonsReducer = (builder: ActionReducerMapBuilder<SalesSettingsState>) => {
  builder.addCase(getSalesPersons.fulfilled, (state, action: PayloadAction<SalesPerson[]>) => {
    state.salesPersons = action.payload;
    state.status = 'succeeded';
  });

  builder.addCase(getSalesPersons.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(getSalesPersons.rejected, state => {
    state.message = 'No se pudo obtener informacion, revise su conexión';
    state.status = 'failed';
  });
};
