import { Flex, InputGroup } from '@chakra-ui/react';
import styled from 'styled-components';

interface ISearch {
  value: string;
  onChange: (value: string) => void;
  placeHolder: string;
}

export const Search = ({ value, onChange, placeHolder }: ISearch) => {
  return (
    <Flex w={'100%'} justifyContent='center'>
      <InputGroup maxWidth={600}>
        <CustomSearch
          placeholder={placeHolder}
          value={value}
          onChange={({ target }) => onChange(target.value)}
        />
      </InputGroup>
    </Flex>
  );
};

const CustomSearch = styled.input`
  margin: 24px auto 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 8px;
  font-size: 1em;
  color: var(--chakra-colors-black-primary);
  background: #f5f8fa;
  border-radius: 8px;
  outline: none;

  &::placeholder {
    font-size: 1em;
    color: var(--chakra-colors-gray-primary);
  }
`;
