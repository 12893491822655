import { Container } from 'components/common/Container/Container';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { useAppSelector } from '../../store/hooks';
import { serializeQuery } from '../../utils/serializeQuery';
import { CLientContact } from '../../components/ClientProfile/CLientContact/CLientContact';
import { ClientInfo } from '../../components/ClientProfile/ClientInfo/ClientInfo';
import { ClientTabs } from './ClientNav/ClientNav';
import { ClientStores } from '../../components/ClientProfile/ClientStores/ClientStores';
import { getClientDetails } from './store/extraReducers/getCLientInfo';
import { EMainNav, setMainNav } from './store/store';

const ClientProfile = () => {
  const { id } = useParams<{ id: string }>();
  const { status, clientDetails, mainNav } = useAppSelector(state => state.clientDetails);
  const customDispatch = useCustomDispatch();

  const dispatch = useDispatch();
  useEffect(() => {
    const prevId = clientDetails?.cliente?.codigoTams as number;

    const params = {
      asyncThuckFuction: getClientDetails,
      endPoint: `api/Clientes/GetClienteDetalles?${serializeQuery({ idCliente: id })}`,
    };

    if (Number(id) === Number(prevId)) return;
    status === 'succeeded' && customDispatch(params);
  }, [clientDetails?.cliente?.codigoTams, customDispatch, id, status]);

  const sections = {
    [EMainNav.tiendas]: <ClientStores />,
    [EMainNav.contacto]: <CLientContact />,
    [EMainNav.informacion]: <ClientInfo />,
  };

  useEffect(() => {
    const currentClient = Number(id);
    const beforeCLient = clientDetails.cliente.codigoTams;
    if (currentClient !== beforeCLient) dispatch(setMainNav(EMainNav.informacion));
  }, [clientDetails.cliente.codigoTams, dispatch, id]);

  return (
    <Container>
      <ClientTabs />
      {sections[mainNav]}
    </Container>
  );
};

export default ClientProfile;
