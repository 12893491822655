import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { BrandCard } from './BrandCard';

const defaultBrand = [{ active: false, image: '', id: '', name: '' }];

const CatalogBrands = ({ setFilters }: { setFilters: any }) => {
  const { brands: allBrands } = useAppSelector(state => state.products);
  const [brands, setBrands] = useState(defaultBrand);

  const formatBrands = () => {
    setBrands(
      allBrands.map(brand => {
        return { active: false, id: brand.id, image: brand.image, name: brand.name };
      })
    );
  };

  useEffect(() => {
    if (allBrands.length) {
      formatBrands();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBrands]);

  const handleSelectBrandClick = (id: string) => {
    const isSelected = brands.filter(brand => brand.id === id);

    if (isSelected.length && isSelected[0].active) {
      formatBrands();
      setFilters({ id: 'noFilter', type: 'brand' });
      return;
    }

    setFilters({ id, type: 'brand' });
    setBrands(
      brands.map(brand =>
        brand.id === id ? { ...brand, active: true } : { ...brand, active: false }
      )
    );
  };

  return allBrands.length ? (
    <Container>
      <Title>Marcas</Title>
      <BrandCardContainer>
        {brands.map(brand => (
          <BrandCard
            key={brand.id}
            id={brand.id}
            active={brand.active}
            image={brand.image}
            name={brand.name}
            brandSelected={handleSelectBrandClick}
          />
        ))}
      </BrandCardContainer>
    </Container>
  ) : null;
};

export default CatalogBrands;

const Container = styled.section`
  width: 95%;
  max-width: 1200px;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 8px;
  margin: 16px auto 0 auto;
`;

const Title = styled.h3`
  font-size: 1.5em;
  font-weight: 700;
`;

const BrandCardContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(121px, 121px));
  column-gap: 20px;
`;
