import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ILines } from '../../../../models/Products';
import httpService from '../../../../services/httpService';
import { IState } from '../common';

export const updateLine = createAsyncThunk('lines/updateLine', httpService);

export const updateLineReducer = (builder: ActionReducerMapBuilder<IState>) => {
  builder.addCase(updateLine.fulfilled, state => {
    const index = state.lines.findIndex((line: ILines) => line.id === state.updateLine.data.id);
    state.lines[index].name = state.updateLine.data.name;
    state.updateLine.status = 'succeeded';
    state.updateLine.message = 'Línea actualizada';
  });

  builder.addCase(updateLine.pending, state => {
    state.updateLine.status = 'pending';
  });

  builder.addCase(updateLine.rejected, state => {
    state.updateLine.status = 'failed';
    state.updateLine.message = 'Error al actualizar línea';
  });
};
