import { useState } from 'react';
import styled from 'styled-components';
import { CancelButton, SaveButton } from '../EditControls/EditControls';
import { Video } from './Video/Video';

interface ICamera {
  onSave: (value: string) => void;
  onClose: () => void;
}

export const Camera = ({ onSave, onClose }: ICamera) => {
  const [picture, setPicture] = useState<string>();

  const save = () => {
    if (picture) onSave(String(picture));
  };

  return (
    <CameraContainer>
      <Video setPicture={setPicture} picture={picture} />
      <SaveButton
        onClick={save}
        position='absolute'
        size='md'
        right='1rem'
        bottom='1rem'
        fontSize='large'
      />
      <CancelButton
        onClick={onClose}
        position='absolute'
        size='md'
        left='1rem'
        bottom='1rem'
        fontSize='large'
      />
    </CameraContainer>
  );
};

const CameraContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
