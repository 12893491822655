import { useAppSelector } from 'store/hooks';
import styled, { css, keyframes } from 'styled-components';

interface IProps {
  active: boolean;
  selectProductLine: any;
}

export const Filter = ({ active, selectProductLine }: IProps) => {
  const { lines } = useAppSelector(state => state.products);

  return (
    <Container active={active}>
      {lines.length &&
        lines.map(({ name, id }) => (
          <FilterButton key={id} onClick={() => selectProductLine({ productLine: name, id })}>
            {name}
          </FilterButton>
        ))}
    </Container>
  );
};

const Container = styled.div`
  width: 207px;
  height: auto;
  position: absolute;
  right: 0;
  top: 40px;
  bottom: auto;
  margin: 0 auto;
  overflow-y: auto;
  z-index: 999;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: #ffff;
  display: none;
  overflow-y: auto;
  padding: 8px;

  ${({ active }: { active: boolean }) =>
    active &&
    css`
      display: block;
      animation: ${fadeInSettings} 0.3s forwards;
      overflow: auto;
    `};
`;

const FilterButton = styled.button`
  width: 100%;
  padding: 4px;
  border: 2px solid var(--chakra-colors-yellow-primary) !important;
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: start;
  font-size: 1em;
  font-weight: 600;
  position: relative;

  &:hover {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background: rgba(244, 236, 34, 0.1);
    }
  }
`;

const fadeInSettings = keyframes`
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(24px);
    opacity: 1;
  }
`;
