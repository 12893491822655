import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { BrandCard } from 'components/Catalog/Brands/BrandCard';

export const Brands = () => {
  const { brands } = useAppSelector(state => state.products);
  return (
    <Container>
      {brands.slice(0, 8).map(brand => (
        <BrandCard
          key={brand.id}
          image={brand.image}
          name={brand.name}
          id={brand.id}
          disabled={true}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 130px));
  column-gap: 12px;
  row-gap: 12px;
`;
