import { object, string, SchemaOf } from 'yup';
import { formValues } from '../components/ClientProfile/CLientContact/ModalForm/ModalForm';

const required = 'Campo requerido';
const min = 'No pueden ser menos de 8 numeros';

export const ClientContactSchema: SchemaOf<formValues> = object()
  .shape({
    celular: string().required(required).min(8, min).defined(),
    correo: string().email('Debe ser un email').required(required).defined(),
    nombre: string().required(required).defined(),
    puesto: string().required(required).defined(),
    telefono: string().required(required).min(8, min).defined(),
  })
  .defined();
