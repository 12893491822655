import { getBrands } from 'features/Catalog/Store/extraReducers/getBrands';
import { getLines } from 'features/Catalog/Store/extraReducers/getLines';
import { getProducts } from 'features/Catalog/Store/extraReducers/getProducts';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { useCustomDispatch } from './useCustomDispatch';

export const useGetCatalogData = () => {
  const dispatch = useCustomDispatch();
  const {
    status: productsStatus,
    products,
    lines,
    brands,
    brandsStatus,
    linesStatus,
  } = useAppSelector(state => state.products);

  // Get all Products
  useEffect(() => {
    if (lines.length && brands.length && !products.length && productsStatus === 'idle') {
      dispatch({
        asyncThuckFuction: getProducts,
        endPoint: 'api/Catalog/GetAll',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsStatus, products, lines, brands]);

  // Get all Lines
  useEffect(() => {
    linesStatus === 'idle' &&
      !lines.length &&
      dispatch({
        asyncThuckFuction: getLines,
        endPoint: 'api/ProductLine/GetAll',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linesStatus, lines]);

  // Get all Brands
  useEffect(() => {
    brandsStatus === 'idle' &&
      !brands.length &&
      dispatch({
        asyncThuckFuction: getBrands,
        endPoint: 'api/ProductBrand/GetAll',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsStatus, brands]);
};
