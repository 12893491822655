import { ColDef } from 'ag-grid-community/main';

export const columns: ColDef[] = [
  {
    flex: 1,
    field: 'codigoTams',
    headerName: 'Código de TAMS',
    sortable: true,
    maxWidth: 180,
    minWidth: 180,
  },
  {
    flex: 1,
    field: 'nombre',
    headerName: 'Nombre',
    wrapText: true,
    resizable: true,
    autoHeight: true,
    minWidth: 160,
  },
  {
    flex: 1,
    field: 'auth0Id',
    headerName: 'Id de autenticación',
    minWidth: 182,
  },
];
